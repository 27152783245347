import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';

export type SetLanguageAction = {
  type:
    | 'LOCALE/SPANISH'
    | 'LOCALE/FRENCH'
    | 'LOCALE/ENGLISH'
    | 'LOCALE/DEUTSCH';
};

export enum Languages {
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  EN = 'en',
}

export type SetLanguageActionCreator = (lang: Languages) => SetLanguageAction;
export interface IInstance {
  name: string;
  background: any;
  logo: any;
  color: string;
  language: Languages;
  messages: any;
}

export type InstanceFetchRequestAction = {
  type: 'INSTANCE/FETCH_REQUEST';
};

export type InstanceFetchSuccessAction = {
  type: 'INSTANCE/FETCH_SUCCESS';
  data: IInstance;
};

export type InstanceFetchFailureAction = {
  type: 'INSTANCE/FETCH_FAILURE';
  error: any;
};

export type InstanceResult<R> = ThunkAction<R, AppState, void, InstanceAction>;

export type FetchInstanceActionCreator = () => InstanceResult<void>;

export type InstanceAction =
  | InstanceFetchRequestAction
  | InstanceFetchSuccessAction
  | InstanceFetchFailureAction;
