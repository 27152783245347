import { WebAuth } from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { IToken, VerifyTokenResponse } from '../redux/modules/user/types';

const {
  REACT_APP_DOMAIN,
  REACT_APP_AUTH_AUDIENCE,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_AUTH_CLIENT_ID,
} = process.env;

export const auth = new WebAuth({
  domain: REACT_APP_AUTH_DOMAIN!,
  clientID: REACT_APP_AUTH_CLIENT_ID!,
  audience: REACT_APP_AUTH_AUDIENCE!,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

export const accessItem = 'cityportal-access';
export const idItem = 'cityportal-id';

export const getAccessToken = () => localStorage.getItem(accessItem) || '';
export const getIdToken = () => localStorage.getItem(idItem) || '';

export const verifyToken = (token: string): VerifyTokenResponse => {
  const tokenObject = jwtDecode<IToken>(token);

  const appMetadata = tokenObject[`${REACT_APP_DOMAIN}/app_metadata`];
  const userMetadata = tokenObject[`${REACT_APP_DOMAIN}/user_metadata`];

  if (Date.now() / 1000 > tokenObject.exp) {
    return {
      error: 'Le jeton a expiré',
    };
  }

  const { email, name, nickname, picture, updatedAt } = tokenObject;

  return {
    user: {
      email,
      name,
      nickname,
      picture,
      updatedAt,
      appMetadata,
      userMetadata,
    },
  };
};
