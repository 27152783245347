import styled from 'styled-components';

interface IContainerProps {
  active?: boolean;
}

export const Container = styled.div<IContainerProps>`
  border: ${props =>
    props.active ? '1px solid ' + props.theme.colors.black : 'none'};
  position: absolute;
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
`;
