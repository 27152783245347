import React from 'react';
import { connect } from 'react-redux';
import { Box, Button } from '../../../components';
import {
  AppState,
  selectEmergenciesByType,
  addEmergencyFromTemplate,
  AddEmergencyFromTemplateActionCreator,
  ITemplate,
} from '../../../redux/modules';
import { WhrapperBodyBox, BoxNoEmergency, SpanBox, DivStyle } from '../styles';
import { injectIntl, InjectedIntl } from 'react-intl';

interface IProps {
  addEmergencyFromTemplate?: AddEmergencyFromTemplateActionCreator;
  emergencies?: ITemplate[];
  typeHeader?: string;
  typeTitle?: string;
  typeBody?: string;
  titleWrapper: string;
  group: string;
  intl?: InjectedIntl;
}

class BoxEmergencyGroup extends React.Component<IProps> {
  handleCreateTemplate = (element: ITemplate) => () => {
    if (element && element._id) {
      this.props.addEmergencyFromTemplate!(element);
    }
  };

  buildTemplatesEmergencies = () => {
    const emergencies = this.props.emergencies;
    return (
      emergencies &&
      emergencies.map((element: ITemplate) => (
        <DivStyle>
          <Button
            type="whiteBlue"
            onClick={this.handleCreateTemplate(element)}
            button={element.name}
          />
        </DivStyle>
      ))
    );
  };
  render() {
    const emergencies = this.props.emergencies;
    return (
      <Box
        typeHeader={this.props.typeHeader}
        typeTitle={this.props.typeTitle}
        typeBody={this.props.typeBody}
        defaultTitleWrapper={this.props.titleWrapper}
      >
        {emergencies && emergencies.length ? (
          <WhrapperBodyBox>{this.buildTemplatesEmergencies()}</WhrapperBodyBox>
        ) : (
          <BoxNoEmergency>
            <SpanBox>
              {this.props.intl!.formatMessage({
                id: 'emergency.emergencyGroup.noElements',
              })}
            </SpanBox>
          </BoxNoEmergency>
        )}
      </Box>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => {
    return {
      emergencies: selectEmergenciesByType(state, props.group),
    };
  },
  { addEmergencyFromTemplate }
)(injectIntl<any>(BoxEmergencyGroup));
