import React, { Component } from 'react';
import ListItemSelect from '../../components/ListItemSelect';
import { CustomDiv, DivCenter, PInfo } from './styles';
import { FormattedMessage } from 'react-intl';

interface IItem {
  id: string;
  title: string;
  subtitle?: string;
}

interface IProps {
  items: IItem[];
}

interface IState {
  activeItems: any[];
}

class ListSelect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { activeItems: [] };
  }

  onClickItem(itemId: string) {
    const currentItemsActives = this.state.activeItems;
    if (itemId) {
      const index = currentItemsActives.findIndex(x => x === itemId);
      if (index < 0) {
        currentItemsActives.push(itemId);
      } else {
        currentItemsActives.splice(index, 1);
      }
      this.setState({ activeItems: currentItemsActives });
    }
  }

  getActiveItems() {
    this.setState({ activeItems: [] });
    return this.props.items.filter(x => this.state.activeItems.includes(x.id));
  }

  selectAll() {
    const currentItemsActives = this.props.items.map(x => x.id);
    this.setState({ activeItems: currentItemsActives });
  }

  renderItems() {
    const { items } = this.props;
    const currentItemsActives = this.state.activeItems;
    if (items && items.length) {
      return items.map((item, i) => (
        <ListItemSelect
          key={`${item.title}_${i}`}
          title={item.title}
          subtitle={item.subtitle || ''}
          active={currentItemsActives.findIndex(x => x === item.id) !== -1}
          onClick={this.onClickItem.bind(this, item.id)}
        />
      ));
    }
  }

  render() {
    const { items } = this.props;
    if (items && items.length) {
      return <CustomDiv>{this.renderItems()}</CustomDiv>;
    } else {
      return (
        <DivCenter>
          <PInfo>
            <FormattedMessage id="screenSelection.panels.info.noItemsSelects" />
          </PInfo>
        </DivCenter>
      );
    }
  }
}
export default ListSelect;
