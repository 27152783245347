import React, { PureComponent, ReactElement, MouseEvent } from 'react';

import { Container } from './styles';

interface IProps {
  children?: string | ReactElement<any>;
  fontSize?: string;
  uppercase?: boolean;
  bold?: boolean;
  link?: boolean;
  onClick?: (event?: MouseEvent<HTMLSpanElement>) => void;
}

export default class Text extends PureComponent<IProps> {
  render() {
    const { children, ...rest } = this.props;
    return <Container {...rest}>{children}</Container>;
  }
}
