import React from 'react';
import { Row } from 'antd';
import { InjectedIntl, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  IElement,
  AppState,
  changeSelection,
  updateElementProp,
  Status,
} from '../../../../../redux/modules';
import { Input } from '../../../../../components';

interface IProps {
  creativeId: string;
  index: number;
  element: IElement;
  updateElementProp?: any;
  changeSelection?: any;
  intl?: InjectedIntl;
  status: Status;
}

interface IState {
  disabled?: boolean;
  isPosting?: boolean;
}

class TextElementClass extends React.PureComponent<IProps, IState> {
  state: IState = {};

  handleEnter = (index: number) => {
    this.props.changeSelection('element', index);
  };

  getHandleChange = (type: string, _: IElement) => (event: any) => {
    this.props.updateElementProp(
      this.props.creativeId,
      type,
      event.target.value
    );
  };

  onPressEnter = (index: number) => {
    this.props.changeSelection('element', index);
  };

  render() {
    const { element, index, status } = this.props;

    return (
      <Row onClick={() => this.handleEnter(index)} key={`row_img_${index}`}>
        <Input
          value={element.content || ''}
          label={element.name}
          disabled={
            status === Status.PUBLISHED || status === Status.PENDING_VALIDATION
          }
          required={!!element.notEmpty}
          placeholder={'editor.info.placeholer.element.text'}
          onChange={this.getHandleChange('content', element)}
          key={`row_text_${index}`}
          onClick={() => this.onPressEnter(index)}
        />
      </Row>
    );
  }
}

export const TextElement = connect(
  (state: AppState, props: IProps) => ({}),
  {
    changeSelection,
    updateElementProp,
  }
)(injectIntl(TextElementClass as any));
