import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, TextTag } from './styles';
import { Status } from '../../redux/modules/creativities/types';
import moment from 'moment';

interface IProps {
  status: Status;
  startDate?: string | undefined;
  endDate?: string | undefined;
}

export default class StatusTag extends PureComponent<IProps> {
  hasStarted = () => {
    const date = moment(this.props.startDate, 'D/M/YYYY');
    const today = moment().startOf('day');
    return date.isSameOrBefore(today);
  };

  hasFinished = () => {
    const date = moment(this.props.endDate, 'D/M/YYYY');
    const today = moment().startOf('day');
    return today.isAfter(date);
  };

  getStatus = (status: Status) => {
    switch (status) {
      case 2:
        return (
          <Tag className="draft-tag">
            <TextTag>
              <FormattedMessage id="tag.draft" />
            </TextTag>
          </Tag>
        );
      case 3:
        return (
          <Tag className="waiting-tag">
            <TextTag>
              <FormattedMessage id="tag.pending" />
            </TextTag>
          </Tag>
        );
      case 4:
        return this.hasStarted() && !this.hasFinished() ? (
          <Tag className="ongoing-tag">
            <TextTag>
              <FormattedMessage id="tag.ongoing" />
            </TextTag>
          </Tag>
        ) : (
          <Tag className="scheduled-tag">
            <TextTag>
              <FormattedMessage id="tag.scheduled" />
            </TextTag>
          </Tag>
        );
      case 5:
        return (
          <Tag className="completed-tag">
            <TextTag>
              <FormattedMessage id="tag.completed" />
            </TextTag>
          </Tag>
        );

      case 1:
      default:
        return (
          <Tag className="created-tag">
            <TextTag>
              <FormattedMessage id="tag.created" />
            </TextTag>
          </Tag>
        );
    }
  };
  render() {
    return this.getStatus(this.props.status);
  }
}
