import styled from 'styled-components';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

export const Content = <any>styled(Tooltip)<TooltipProps>`
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background: ${props => props.theme.colors.lightGrey};
    }
  }`;
