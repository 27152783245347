import React, { PureComponent } from 'react';
import { Icon, Tooltip } from 'antd';

import { PlaybacksText, Wrapper } from './styles';
import { InjectedIntl, injectIntl } from 'react-intl';

interface IProps {
  totalPlaybacks?: String;
  intl?: InjectedIntl;
}

function formatter(input?: String) {
  if (!input) {
    return 0;
  }

  const num = parseInt(input.valueOf());

  return Math.abs(num) > 999
    ? Math.sign(num) * parseInt((Math.abs(num) / 1000).toFixed(1)) + 'k'
    : Math.sign(num) * Math.abs(num);
}

class PlaybacksTag extends PureComponent<IProps> {
  public render() {
    const { totalPlaybacks } = this.props;

    return (
      <Tooltip
        title={this.props.intl!.formatMessage(
          {
            id: 'cardInfo.detail.playbacks',
          },
          { playbacks: totalPlaybacks ? totalPlaybacks.valueOf() : '0' }
        )}
      >
        <Wrapper>
          <Icon
            type="play-circle"
            theme="outlined"
            style={{ color: '#0099D8' }}
          />
          <PlaybacksText>{formatter(totalPlaybacks)}</PlaybacksText>
        </Wrapper>
      </Tooltip>
    );
  }
}

export default injectIntl<any>(PlaybacksTag);
