import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '../Text';
import { NoResults } from './styles';

export default class NoCards extends PureComponent {
  render() {
    return (
      <NoResults>
        <Text bold>
          <FormattedMessage id="creativeList.nocreatives" />
        </Text>
      </NoResults>
    );
  }
}
