import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import {
  AppState,
  Filter,
  StatusFilter,
  changeSortValue,
} from '../../../redux/modules';
import { TypeOfSorts } from '../../../redux/modules/creativities/types';
import { Select } from '../../../components';

import { Body, Container } from './styles';

interface IProps {
  currentStatus?: StatusFilter;
  intl?: InjectedIntl;
  sortHandler?: any;
  changeSortValue?: any;
  sort?: any;
}

const typeSortList: {
  key: TypeOfSorts;
  label: string;
  value: TypeOfSorts;
}[] = [
  {
    key: TypeOfSorts.NAME,
    label: 'Name',
    value: TypeOfSorts.NAME,
  },
  {
    key: TypeOfSorts.CREATION,
    label: 'Creation',
    value: TypeOfSorts.CREATION,
  },
  {
    key: TypeOfSorts.START,
    label: 'Start',
    value: TypeOfSorts.START,
  },
  {
    key: TypeOfSorts.END,
    label: 'End',
    value: TypeOfSorts.END,
  },
];

class SortBox extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    this.sortHandler = this.sortHandler.bind(this);
  }

  sortHandler = (value: TypeOfSorts) => {
    this.props.changeSortValue(value);
  };

  render() {
    const { intl } = this.props;

    return (
      <Body>
        <FormattedMessage id="creativeList.sort" />
        <Container>
          <Select
            items={typeSortList}
            defaultValue={intl!.formatMessage({
              id: 'creativeList.sort.date',
            })}
            onPress={this.sortHandler}
            placeholder={intl!.formatMessage({
              id: 'creativeList.sort.date',
            })}
            value={this.props.sort}
          />
        </Container>
      </Body>
    );
  }
}

export default connect(
  (state: AppState) => ({
    currentStatus: state.filters.filters[Filter.STATUS],
    sort: state.creativities.sort,
  }),
  {
    changeSortValue,
  }
)(injectIntl<any>(SortBox));
