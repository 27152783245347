import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Container, CustomInput, Label } from './styles';

interface IProps {
  label?: string;
  value?: string;
  placeholder?: string;
  intl: any;
  onChange?: React.ChangeEventHandler<any>;
  onClick?: React.PointerEventHandler<any>;
  required?: boolean;
  name?: string;
  type?: string;
  maxLength?: number;
  disabled?: boolean;
}

class Input extends PureComponent<IProps> {
  public render() {
    const { label, placeholder, intl, type, maxLength, ...rest } = this.props;

    return (
      <Container>
        {!!label && <Label>{label}</Label>}
        <CustomInput
          type={type ? type : 'text'}
          placeholder={intl.formatMessage({ id: placeholder })}
          maxLength={maxLength || 80}
          {...rest}
        />
      </Container>
    );
  }
}

export default injectIntl(Input);
