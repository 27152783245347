import styled from 'styled-components';
import { Select } from 'antd';

interface ISelectProps {
  width?: string;
  type?: string;
}

export const CustomSelect = styled(<any>Select)<ISelectProps>`
  .ant-select-selection {
    width: 100%;
    min-width: 200px;
    background-color: ${props =>
      props.type ? props.theme.colors.blue : props.theme.colors.white};
    color: ${props =>
      props.type ? props.theme.colors.white : props.theme.colors.grey};
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .ant-select-selection__rendered {
    width: ${props => (props.width ? props.width + 'px' : '100%')};
  }
  .ant-select-arrow {
    color: ${props =>
      props.type ? props.theme.colors.white : props.theme.colors.grey};
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.S};
  font-weight: ${props => props.theme.fontWeights.medium};
  display: block;
  margin-bottom: 5px;
`;

export const Option = styled(Select.Option)``;
