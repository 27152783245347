import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history';
import createRootReducer from '../modules';

const history = createHistory();

export const configureStore = () => {
  const reducer = createRootReducer(connectRouter(history));

  let middleware;

  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    const { createLogger } = require('redux-logger');
    const logger = createLogger({ collapsed: true });
    middleware = composeWithDevTools(
      applyMiddleware(routerMiddleware(history), thunk, logger)
    );
  } else {
    middleware = applyMiddleware(routerMiddleware(history), thunk);
  }

  const store = createStore(reducer, {}, middleware);

  return {
    history,
    store,
  };
};
