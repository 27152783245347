import styled from 'styled-components';

interface IType {
  typeBody?: string;
  typeHeader?: string;
  typeTitle?: string;
}

const headerWrappersColor = (props: { typeHeader: any; theme: any }) => {
  const { typeHeader, theme } = props;
  switch (typeHeader) {
    case 'blue':
      return `background-color: ${theme.colors.blue}`;
    default:
      return `background-color: ${theme.colors.superlightGrey}`;
  }
};

const bodyWrapperColor = (props: { typeBody: any; theme: any }) => {
  const { typeBody, theme } = props;
  switch (typeBody) {
    case 'grey':
      return `background-color: ${theme.colors.superlightGrey}`;
    case 'orange':
      return `background-color: ${theme.colors.alertSoftOrange}`;
    case 'yellow':
      return `background-color: ${theme.colors.alertPaleYellow}`;
    case 'green':
      return `background-color: ${theme.colors.alertLightGreen}`;
    case 'bluealert':
      return `background-color: ${theme.colors.superLightBlue}`;
    case 'secondary':
      return `background-color: ${theme.colors.LightGrey}`;
    default:
      return `background-color: ${theme.colors.white}`;
  }
};

const titleWrapperColor = (props: { typeTitle: any; theme: any }) => {
  const { typeTitle, theme } = props;
  switch (typeTitle) {
    case 'white':
      return `color: ${theme.colors.white}`;
    default:
      return `color: ${theme.colors.megaDarkGrey}`;
  }
};

export const Wrapper = styled.div<IType>`
  height: auto;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const HeaderWrapper = styled.div<IType>`
  height: 48px;
  border-radius: 4px 4px 0px 0px;
  ${headerWrappersColor};
`;

export const BodyWrapper = styled.div<IType>`
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
  ${bodyWrapperColor};
`;

export const TitleWrapper = styled.p<IType>`
  font-size: ${props => props.theme.sizes.L};
  font-weight: ${props => props.theme.fontWeights.semiBold};
  line-height: 4px;
  padding: 25px;
  ${titleWrapperColor};
`;
