import { IPlayer } from '../players';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '..';

export interface ICreativity {
  selected?: boolean | undefined;
  createdAt?: string;
  updatedAt: string;
  _id: string;
  emergency?: { state?: string };
  campaign?: ICampaign;
  format: IFormat;
  name?: string;
  slides: ISlide[];
  visibility?: IVisibility;
  publishedAt?: string;
  allPlayers?: boolean;
  players?: IPlayer[];
  groups?: string[];
  isPublished?: string | false;
  time?: boolean | undefined;
  status: Status;
  statusNum?: StatusNum;
  startDate?: string;
  endDate?: string;
  totalPlaybacks?: String;
}

export enum Status {
  CREATED = 1,
  DRAFT = 2,
  PENDING_VALIDATION = 3,
  PUBLISHED = 4,
  COMPLETED = 5,
}

export enum StatusNum {
  All = 0,
  Created = 1,
  Draft = 2,
  Pending = 3,
  Published = 4,
  Completed = 5,
}

export enum TypeOfSorts {
  NONE = '',
  NAME = 'name',
  CREATION = 'createdAt',
  START = 'startDate',
  END = 'endDate',
}
interface ICampaign {
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  name: string;
  advertiser: IAdvertiser;
}

interface IAdvertiser {
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  name: string;
  active?: boolean;
  client: IClient;
}

interface IClient {
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  name: string;
  active?: boolean;
}

export interface IFormat {
  createdAt?: string;
  updatedAt?: string;
  _id?: string;
  name?: string;
  group?: object;
  width?: number;
  height?: number;
  size?: string;
}

export interface IVisibility {
  state?: any;
  rules?: any[];
}

export interface ISlide {
  name: any;
  elements: IElement[];
}

export interface IElement {
  name: string;
  type: string;
  style: any;
  content?: string;
  url?: string;
  notEmpty?: boolean;
  locked?: boolean;
  sources?: any;
}

export type VisibilityRuleType = 'date' | 'time' | 'weekDay';

export enum VisibilityRuleComparator {
  FROM = 'gte',
  TO = 'lte',
  EQUAL = 'eq',
  IN = 'in',
}

export type CreativityFetchRequestAction = {
  type: 'CREATIVITIES/FETCH_REQUEST';
};

export type CreativityFetchSuccessAction = {
  type: 'CREATIVITIES/FETCH_SUCCESS';
  data: ICreativity;
};

export type CreativityFetchFailureAction = {
  type: 'CREATIVITIES/FETCH_FAILURE';
  error: any;
};

export type CreativityFetchAllRequestAction = {
  type: 'CREATIVITIES/FETCH_ALL_REQUEST';
};

export type CreativityFetchAllSuccessAction = {
  type: 'CREATIVITIES/FETCH_ALL_SUCCESS';
  data: ICreativity[];
};

export type CreativityFetchAllFailureAction = {
  type: 'CREATIVITIES/FETCH_ALL_FAILURE';
  error: any;
};

export type CreativityAddRequestAction = {
  type: 'CREATIVITIES/ADD_REQUEST';
};

export type CreativityAddSuccessAction = {
  type: 'CREATIVITIES/ADD_SUCCESS';
  data: ICreativity;
};

export type CreativityAddFailureAction = {
  type: 'CREATIVITIES/ADD_FAILURE';
  error: any;
};

export type CreativityUpdateRequestAction = {
  type: 'CREATIVITIES/UPDATE_REQUEST';
};

export type CreativityUpdateSuccessAction = {
  type: 'CREATIVITIES/UPDATE_SUCCESS';
  data: ICreativity;
};

export type CreativityUpdateFailureAction = {
  type: 'CREATIVITIES/UPDATE_FAILURE';
  error: any;
};

export type CreativityUpdateElementProp = {
  type: 'CREATIVITIES/UPDATE_ELEMENT_PROP';
  id: string;
  prop: string;
  value: string;
};

export type CreativityChangeSelection = {
  type: 'CREATIVITIES/CHANGE_SELECTION';
  section: string;
  value: number;
};

export type CreativityChangeSortValue = {
  type: 'CREATIVITIES/CHANGE_SORT_VALUE';
  value: number;
};

export type CreativityUpdateTitle = {
  type: 'CREATIVITIES/UPDATE_TITLE';
  id: string;
  section: string;
  name: string;
};

export type CreativityUpdateVisibility = {
  type: 'CREATIVITIES/UPDATE_VISIBILITY';
  id: string;
  data: IVisibility;
};

export type CreativityUnpublish = {
  id: string;
  type: 'CREATIVITIES/UNPUBLISH';
};

export type CreativityUpdatePlayers = {
  type: 'CREATIVITIES/UPDATE_PLAYERS';
  id: string;
  players: IPlayer[];
};

export type CreativityUpdateGroups = {
  type: 'CREATIVITIES/UPDATE_GROUPS';
  id: string;
  groups: string[];
};

export type CreativityUpdateProp = {
  type: 'CREATIVITIES/UPDATE_PROP';
  id: string;
  update: Partial<ICreativity>;
};

export type CreativityDeleteRequestAction = {
  type: 'CREATIVITIES/DELETE_REQUEST';
};

export type CreativityDeleteSuccessAction = {
  type: 'CREATIVITIES/DELETE_SUCCESS';
  id: string;
};

export type CreativityDeleteFailureAction = {
  type: 'CREATIVITIES/DELETE_FAILURE';
  error: any;
};

export type ActionResult<R> = ThunkAction<R, AppState, void, CreativityAction>;

export type UnpublishCreativityByWarningActionCreator = (
  creativity: ICreativity
) => ActionResult<void>;

export type UpdateVisibilityActionCreator = (
  id: string,
  group: VisibilityRuleType,
  typeVisibility: VisibilityRuleComparator,
  value: string | number
) => ActionResult<void>;

export type UpdateCreativityActionCreator = (
  id: string,
  status: Status
) => ActionResult<void>;

export type AddCreativityFromTemplateActionCreator = (
  data: {
    _id: string;
    name: string;
  }
) => ActionResult<void>;

export type AddEmergencyFromTemplateActionCreator = (
  data: {
    _id: string;
    name: string;
  }
) => ActionResult<void>;

export type FetchCreativityActionCreator = (id: string) => ActionResult<void>;

export type FetchCreativitiesActionCreator = () => ActionResult<void>;

export type EmergencyAddRequestAction = {
  type: 'EMERGENCIES/ADD_REQUEST';
};

export type EmergencyAddSuccessAction = {
  type: 'EMERGENCIES/ADD_SUCCESS';
  data: ICreativity;
};

export type EmergencyAddFailureAction = {
  type: 'EMERGENCIES/ADD_FAILURE';
  error: any;
};

export type CreativityFetchJobsRequestAction = {
  type: 'CREATIVITIES/FETCH_JOBS_REQUEST';
};

export type CreativityFetchJobsSuccessAction = {
  type: 'CREATIVITIES/FETCH_JOBS_SUCCESS';
  data: {
    jobs: any;
    id: string;
  };
};

export type CreativityFetchJobsFailureAction = {
  type: 'CREATIVITIES/FETCH_JOBS_FAILURE';
  error: any;
};

export type CreativitiesResetError = {
  type: 'CREATIVITIES/RESET_ERROR';
};

export type setCreativeDefaultRequestAction = {
  type: 'CREATIVE/SET_DEFAULT_REQUEST';
};

export type setCreativeDefaultSuccessAction = {
  type: 'CREATIVE/SET_DEFAULT_SUCCESS';
  data: {
    id: string;
  };
};

export type setCreativeDefaultFailureAction = {
  type: 'CREATIVE/SET_DEFAULT_FAILURE';
  error: any;
};

export type changeCreativeStatusRequestAction = {
  type: 'CREATIVE/CHANGE_STATUS_REQUEST';
};

export type changeCreativeStatusSuccessAction = {
  type: 'CREATIVE/CHANGE_STATUS_SUCCESS';
  status: Status;
  creativeId: string;
};

export type changeCreativeStatusFailureAction = {
  type: 'CREATIVE/CHANGE_STATUS_FAILURE';
  error: any;
};

export type resetCreativitiesErrorActionCreator = () => ActionResult<void>;

export type FetchEmergenciesActionCreator = (data: any) => ActionResult<void>;

export type CreativityAction =
  | CreativityFetchRequestAction
  | CreativityFetchSuccessAction
  | CreativityFetchFailureAction
  | CreativityFetchAllRequestAction
  | CreativityFetchAllSuccessAction
  | CreativityFetchAllFailureAction
  | CreativityAddRequestAction
  | CreativityAddSuccessAction
  | CreativityAddFailureAction
  | CreativityUpdateRequestAction
  | CreativityUpdateSuccessAction
  | CreativityUpdateFailureAction
  | CreativityUpdateElementProp
  | CreativityChangeSelection
  | CreativityChangeSortValue
  | CreativityUpdateTitle
  | CreativityUpdateVisibility
  | CreativityUnpublish
  | CreativityUpdatePlayers
  | CreativityUpdateGroups
  | CreativityUpdateProp
  | CreativityFetchJobsRequestAction
  | CreativityFetchJobsSuccessAction
  | CreativityFetchJobsFailureAction
  | EmergencyAddRequestAction
  | EmergencyAddSuccessAction
  | EmergencyAddFailureAction
  | CreativityDeleteRequestAction
  | CreativityDeleteSuccessAction
  | CreativityDeleteFailureAction
  | CreativitiesResetError
  | setCreativeDefaultRequestAction
  | setCreativeDefaultSuccessAction
  | setCreativeDefaultFailureAction
  | changeCreativeStatusRequestAction
  | changeCreativeStatusSuccessAction
  | changeCreativeStatusFailureAction;
