export default {
  error: 'Error',
  success: 'Éxito',
  'editor.empty': 'Campo vacío',
  'editor.loader': 'Cargando creatividad...',
  'editor.info.title': 'Editor creativo',
  'editor.info.emergency.title': 'Edición de alerta de emergencia',
  'editor.info.field.creativity': 'Nombre de la creatividad',
  'editor.info.field.slide': 'Nombre de la diapositiva',
  'editor.info.placeholer.creativity': 'Insertar el nombre de la creatividad',
  'editor.info.placeholer.slide': 'Insertar el nombre de la diapositiva',
  'editor.info.placeholer.element.text': 'Insertar contenido',
  'editor.info.placeholer.element.image': 'Insertar URL',
  'editor.info.locations.title': 'Ubicaciones',
  'editor.info.locations.playersScreenTitle':
    '{number} pantallas seleccionadas',
  'editor.info.locations.groupsScreenTitle': '{number} grupo seleccionado',
  'editor.info.locations.seeTitle': 'Ver ubicaciones',
  'editor.info.locations.allLocationsTitle': 'Todas las ubicaciones',
  'editor.info.locations.specificLocationsTitle': 'Ubicaciones específicas',
  'editor.info.screen.buttonSelectScreen': 'Pantalla específica...',
  'editor.info.screen.titleActivity': 'Fecha y hora',
  'editor.info.elements.sectionTitle': 'Contenido',

  'editor.error.uploadImage.InvalidFileDimensions':
    'Las dimensiones de la imagen no coinciden con el original',
  'editor.error.uploadImage.InvalidFileExtension':
    'La extensión de la imagen debe ser jpg, jpeg, png o svg',
  'editor.error.uploadImage.default': 'Falló la carga de la imagen',
  'editor.error.uploadVideo.dimensiones incorrectas':
    'Las dimensiones del video no coinciden con el original',
  'editor.error.uploadVideo.InvalidFileExtension':
    'La extensión de video debe ser mp4, mov, avi, m4a, 3gp',
  'editor.error.uploadVideo.default': 'Falló la carga del video',

  'screenSelection.title': 'Selección de pantalla',
  'screenSelection.phraseSubtitle':
    'Todas las pantallas incluidas posteriormente en los grupos seleccionados se seleccionarán automáticamente',
  'screenSelection.tabs.option1': 'Seleccionar por grupo',
  'screenSelection.tabs.option2': 'Seleccionar por pantallas',
  'dashboard.bygroup': 'Por grupo',
  'dashboard.next': 'Siguiente',
  'dashboard.current': 'Actual',

  'modal.footer.buttonOk': 'Listo',
  'modal.footer.buttonSubmit': 'Enviar',
  'modal.footer.buttonClock': 'Ok',
  'modal.body.title': 'Tu sesión ha expirado',
  'modal.body.text':
    'Su sesión fue cerrada porque detectamos inactividad. Para continuar, vuelva a iniciar sesión.',

  'screenSelection.panels.button.selectAll': 'seleccionar todo',
  'screenSelection.panels.button.addSelect': 'añadir a la selección',
  'screenSelection.panels.button.removeSelection': 'eliminar de la selección',
  'screenSelection.panels.info.noItemsSelects':
    'No hay grupos seleccionados. El título de tu creatividad no aparecerá en ninguna pantalla',

  'cardInfo.detail.template': 'Plantilla:',
  'cardInfo.detail.author': 'Autor:',
  'cardInfo.detail.created': 'Creado:',
  'cardInfo.detail.start': 'Inicio:',
  'cardInfo.detail.end': 'Fin:',
  'cardInfo.detail.totalPlaybacks': 'Total playbacks:',
  'cardInfo.detail.playbacks': '{playbacks} playbacks',

  'creativeList.byStatus': 'Mostrar',
  'creativeList.nocreatives': 'No se encontraron creatividades',
  'creativeList.selectTemplate':
    'Seleccione la plantilla base para su nueva creatividad',
  'creativeList.filterCreative': 'Filtrar creatividades',
  'creativeList.tag.showing': 'Mostrando',
  'creativeList.tag.all': 'todos',
  'creativeList.tag.drafts': 'borrador',
  'creativeList.tag.published': 'publicado',
  'creativeList.tag.completed': 'completado',
  'creativeList.tag.pending': 'pendiente',
  'creativeList.tag.scheduled': 'planificado',
  'creativeList.tag.ongoing': 'en curso',
  'creativeList.sort': 'Ordenar por',
  'creativeList.sort.date': 'Fecha de creación',
  'creativeList.sort.name': 'Nombre',
  'creativeList.sort.creation': 'Fecha de creación',
  'creativeList.sort.start': 'Fecha de inicio',
  'creativeList.sort.end': 'Fecha de finalización',

  'route.default': 'CityPortal',
  'route.dashboard': 'Dashboard',
  'route.creative': 'Gestor de campañas',
  'route.emergency': 'Alertas de emergencia',
  'route.login': 'Iniciar sesión',

  'component.upload': 'o',
  'component.uploadButton': 'Haga clic para cargar',

  'condition.clear': 'Borrar',
  'condition.partly cloudy': 'Parcialmente nublado',
  'condition.cloudy': 'Nublado',
  'condition.rain': 'Lluvia',
  'condition.drizzle': 'Llovizna',
  'condition.hail': 'Hail',
  'condition.thunderstorm': 'Tormenta',
  'condition.snow': 'Nieve',
  'condition.fog': 'Niebla',
  'condition.haze': 'Neblina',
  'condition.sand': 'Arena',
  'condition.dust': 'Polvo',
  'condition.smoke': 'Humo',
  'condition.ash': 'Ceniza',
  'condition.squalls': 'Tormentas',
  'condition.unknown': 'Desconocido',
  'condition.undefined': 'Desconocido',
  'condition.chance': 'Oportunidad',

  'user.username': 'Nombre de usuario',
  'user.password': 'Contraseña',

  'app.logout': 'Cerrar sesión',

  'actions.modalPublishTitle': 'Publicar creatividad',
  'actions.modalDeleteTitle': '¿Eliminar creatividad?',
  'actions.modalDeleteText':
    'Una vez eliminada la creatividad, no se puede recuperar',
  'actions.modalConfirm': 'Confirmar',
  'actions.deleteModalConfirm': 'Eliminar',
  'actions.modalCancel': 'Cancelar',
  'actions.creative.modalText': '¡Atención! ¿Quieres publicarlo?',
  'actions.modalText': '¿Está seguro de que desea publicar esta creatividad?',
  'actions.buttons.deactivate': 'Desactivar',
  'actions.buttons.save': 'Guardar',
  'actions.buttons.publish': 'Publicar',
  'actions.buttons.validate&publish': 'Validar y publicar',
  'actions.buttons.requestValidation': 'Solicitar validación',
  'actions.buttons.delete': 'Eliminar',
  'actions.buttons.unpublish': 'Anular publicación',
  'actions.buttons.play': 'Reproducir',
  'actions.buttons.reset': 'Restablecer',
  'actions.button.send': 'Enviar',
  'actions.buttons.popOver.isDefault':
    'La creatividad no se puede eliminar porque está configurada como predeterminada',
  'actions.buttons.denyComment': 'Denegar y comentar',
  'actions.buttons.approvePublish': 'Aprobar y publicar',
  'editor.visibility.from': 'Seleccionar intervalo de fechas',
  'editor.visibility.dateRange': 'Seleccionar intervalo de fechas',
  'editor.visibility.to': 'to',
  'select.defaultValue.template': 'Nueva campaña',
  'dashboard.phraseWelcome': 'Hola',
  'emergency.emergencyGroup.noElements': 'Sin alertas de emergencia',
  'emergency.titleGroups.terrorirsm': 'Terrorismo',
  'emergency.titleGroups.natural': 'Natural',
  'emergency.titleGroups.technologic': 'Tecnológico',
  'emergency.titleGroups.sanitary': 'Sanitario',
  'passwordReset.back': 'Volver al inicio de sesión',
  'passwordReset.sendAgain': 'Enviar de nuevo',
  'passwordReset.recover': 'Recuperar usuario/contraseña',
  'passwordReset.error.invalidEmail': 'Correo electrónico incorrecto',

  'editor.error.notEmpty.description': 'El campo no debe estar vacío',

  'creativeList.placeholder': 'Nuevo desde plantilla',
  'creativeList.create': 'Agregar',
  'creativeList.createCreative': 'Agregar una nueva creatividad',
  'creativeList.deletePopOver.title': 'La creatividad no se puede eliminar si:',
  'creativeList.deletePopOver.text1':
    '- Es una creatividad configurada por defecto',
  'creativeList.deletePopOver.text2':
    '- Es una creatividad programada o publicada',
  'creativeList.deletePopOver.text3':
    '- Es una creatividad en espera de validación',
  'creativeList.defaultCreative.message': 'Creatividad predeterminada elegida',
  'creativeList.deleteCreative.message': 'Eliminación de creatividad',
  'creativeList.edit.tooltip.title': 'Editar creatividad',
  'creativeList.alreadyDefault.popOver':
    'Esta creatividad ya está configurada como predeterminada',
  'creativeList.notAdmin.popOver':
    'No tienes permiso para establecer una creatividad como predeterminada',
  'creativeList.setDefault.tooltip.title': 'Establecer como predeterminado',
  'creativeList.delete.tooltip.title': 'Eliminar creatividad',

  'user.errorlogin': 'Error de inicio de sesión',
  'emergency.deactivate': 'Desactivar',
  'emergency.alertActive': 'Alerta de emergencia activa',
  'app.all': 'All',
  'app.reset': 'Restablecer',
  'dashboard.allGroups': 'Todos los grupos',
  'emergency.publishAlert': 'Publicar',
  'emergency.terrorism': 'Terrorismo',
  'emergencia.natural': 'Natural',
  'emergency.sanitary': 'Sanitario',
  'emergency.technologic': 'Tecnológico',
  'emergency.noEmergencies': 'Sin emergencias',

  'All groups': 'Todos los grupos',
  All: 'All',
  Published: 'Publicado',
  Draft: 'Borrador',
  Completed: 'Completado',
  'On Going': 'En curso',
  Pending: 'Pendiente',
  Scheduled: 'Planificado',
  Name: 'Nombre',
  Creation: 'Fecha de creación',
  Start: 'Fecha de inicio',
  End: 'Fecha de finalización',
  'Missing required parameter: username':
    'Falta el parámetro requerido: nombre de usuario',
  'Missing required parameter: password':
    'Falta el parámetro requerido: contraseña',
  'Wrong email or password.': 'Correo electrónico o contraseña incorrectos.',

  "We've just sent you an email to reset your password.":
    'Acabamos de enviarle un correo electrónico para restablecer su contraseña.',

  'editor.uploadImage.success': 'Imagen cargada correctamente',
  'editor.uploadVideo.success': 'Video cargado con éxito',

  ddmmaa: 'DD/MM/AA',

  'weekdayselector.all': 'Toda la semana',
  'weekdayselector.specificDays': 'Días específicos',
  'weekdayselector.monday': 'M',
  'weekdayselector.tuesday': 'T',
  'weekdayselector.wednesday': 'W',
  'weekdayselector.thursday': 'T',
  'weekdayselector.friday': 'F',
  'weekdayselector.saturday': 'S',
  'weekdayselector.sunday': 'S',

  'HourSelector.allHours': 'Todas las horas',
  'HourSelector.specificHours': 'Horas específicas',

  'loading.message': 'Procesando video, puede tomar hasta 2 minutos',

  'tag.draft': 'Borrador',
  'tag.pending': 'Pendiente de validación',
  'tag.created': 'Creado',
  'tag.completed': 'Completado',
  'tag.ongoing': 'En curso',
  'tag.scheduled': 'Programado',
};
