import styled from 'styled-components';
import { DatePicker, TimePicker } from 'antd';

export const CustomDatePicker = styled.div`
  margin-left: 20px;
`;

export const CustomPicker = styled.div``;

const isDisabled = (props: { disabled?: boolean }) => {
  if (props.disabled) {
    return `
      background: #fafafa;
      cursor: not-allowed;
    `;
  }

  return `cursor: pointer`;
};

export const Date = styled(DatePicker)`
  .ant-input {
    ${isDisabled};
    height: 40px;
  }
`;

export const Time = styled(TimePicker)`
  width: 100%;
  .ant-time-picker-input {
    ${isDisabled};
    height: 40px;
  }
`;
