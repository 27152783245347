import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;

export const Logo = styled.img`
  height: auto;
  width: 305px;
  margin-bottom: 20px;
`;

export const Name = styled.p`
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
  font-size: ${props => props.theme.sizes.XXL};
  letter-spacing: -0.04em;
  font-weight: 200;
  margin: 0;
`;
