import { StatusFilter, Filter } from '../filters/types';
import { IFilterState } from '../filters/reducer';
import { ICreativity, Status, StatusNum } from '../creativities/types';

export const statusFilterList: {
  key: StatusFilter;
  label: string;
  value: StatusFilter;
}[] = [
  { key: StatusFilter.ALL, label: 'All', value: StatusFilter.ALL },
  { key: StatusFilter.DRAFT, label: 'Draft', value: StatusFilter.DRAFT },
  ...(process.env.REACT_APP_PENDING_STATE
    ? [
        {
          key: StatusFilter.PENDING,
          label: 'Pending',
          value: StatusFilter.PENDING,
        },
      ]
    : []),
  {
    key: StatusFilter.SCHEDULED,
    label: 'Scheduled',
    value: StatusFilter.SCHEDULED,
  },
  { key: StatusFilter.ONGOING, label: 'On Going', value: StatusFilter.ONGOING },
  {
    key: StatusFilter.COMPLETED,
    label: 'Completed',
    value: StatusFilter.COMPLETED,
  },
];

export const groupFilterList = [];

export const filtersSelector = (type: Filter) => (state: {
  filters: IFilterState;
}) => {
  const filter = state.filters.filters[type];

  if (!filter) {
    return filterOps.default;
  }

  return filterOps[type][filter]
    ? filterOps[type][filter]()
    : filterOps[type].selection(filter);
};

const filterOps: {
  [key: string]: any;
} = {
  default: () => true,
  [Filter.GROUP]: {
    All: () => () => true,
    selection: (value: string) => (creativity: ICreativity) =>
      (creativity && creativity.groups && creativity.groups.includes(value)) ||
      creativity.allPlayers,
  },
  [Filter.STATUS]: {
    [StatusFilter.ALL]: () => () => true,
    selection: (values: string[]) => (creativity: ICreativity) => {
      return (
        values &&
        values.length &&
        (values.map(value => StatusNum[value]).includes(StatusNum['All']) ||
          values.map(value => StatusNum[value]).includes(creativity.status))
      );
    },
    [StatusFilter.ONGOING]: () => (creativity: ICreativity) =>
      creativity.status === Status.PUBLISHED,
    [StatusFilter.SCHEDULED]: () => (creativity: ICreativity) =>
      creativity.status === Status.PUBLISHED,
    [StatusFilter.DRAFT]: () => (creativity: ICreativity) =>
      creativity.status === Status.DRAFT,
    [StatusFilter.PENDING]: () => (creativity: ICreativity) =>
      creativity.status === Status.PENDING_VALIDATION,
    [StatusFilter.COMPLETED]: () => (creativity: ICreativity) =>
      creativity.status === Status.COMPLETED,
  },
};
