import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0px;
  max-width: 400px;
`;
