import styled from 'styled-components';
import { Link, Text } from '../../components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  justify-content: center;
  align-items: center;
`;

export const RightContainer = styled.div<{ background: string }>`
  background: url(${props => props.background}) center;
  background-size: cover;
  width: 100%;
`;

export const LoginHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTile = styled.h2`
  color: ${props => props.theme.colors.megaDarkGrey};
  font-size: ${props => props.theme.sizes.XL};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 29px;
  text-align: center;
`;

export const ContainerlText = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalText = styled.p`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.L};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 21px;
  text-align: center;
  max-width: 260px;
  padding-bottom: 10px;
`;

export const Recover = styled(Link)`
  margin-top: 10px;
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  button {
    min-height: 40px;
    width: 100%;
  }
`;
export const ContainerError = styled.div`
  margin-top: -10px;
`;

export const ErrorMessage = styled(Text)`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.sizes.M};
  font-weight: bold;
  align-self: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img<any>`
  padding: 24px 0;
`;
