import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CustomCheckbox = styled(Checkbox)`
  font-family: 'Montserrat';
  margin: 10px;
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${props => props.theme.colors.white};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.blue};
  }
`;
