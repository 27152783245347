import { TemplatesAction } from './types';

export interface ITemplateState {
  all: any[];
  isFetching: boolean;
  error?: any;
}

const initialState: ITemplateState = {
  all: [],
  isFetching: false,
};

export const templates = (state = initialState, action: TemplatesAction) => {
  switch (action.type) {
    case 'TEMPLATES/FETCH_ALL_REQUEST': {
      return {
        ...state,
        isFetching: true,
      };
    }
    case 'TEMPLATES/FETCH_ALL_SUCCESS': {
      return {
        ...state,
        all: action.data,
        isFetching: false,
      };
    }
    case 'TEMPLATES/FETCH_ALL_FAILURE': {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
