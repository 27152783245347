import styled from 'styled-components';

interface IDivSelectProps {
  onClick?: React.MouseEventHandler;
  active?: boolean;
}

export const DivSelect = styled.div<IDivSelectProps>`
  background: ${props =>
    props.active
      ? `${props.theme.colors.superLightBlue};`
      : `${props.theme.colors.superlightGrey};`};
  min-height: 47px;
  cursor: pointer;
  display: grid;
  border-radius: 4px;
  padding: 5px;

  .title {
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.fontWeights.semiBold};
  }

  .subtitle {
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
