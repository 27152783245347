import React, { PureComponent } from 'react';
import { Wrapper, Text } from './styles';
import Button from '../../components/Button';
import {
  UnpublishCreativityByWarningActionCreator,
  ICreativity,
} from '../../redux/modules/creativities';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';

interface IProps {
  emergency: ICreativity;
  unpublishCreativityByWarning: UnpublishCreativityByWarningActionCreator;
  intl?: InjectedIntl;
}

class Weatherwarning extends PureComponent<IProps> {
  render() {
    return (
      <Wrapper>
        <div>
          <Text>
            <FormattedMessage id="emergency.alertActive" />
          </Text>
          <Text>{this.props.emergency.name}</Text>
        </div>
        <Button
          onClick={() => {
            this.props.unpublishCreativityByWarning(this.props.emergency);
          }}
          button={this.props.intl!.formatMessage({
            id: 'emergency.deactivate',
          })}
          type={'warning'}
          padding={10}
        />
      </Wrapper>
    );
  }
}

export default injectIntl<any>(Weatherwarning);
