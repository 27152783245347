import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState, isAdmin, logout } from '../../redux/modules';

interface IProps {
  isAdmin: boolean;
  loggingOut?: boolean;
  authenticated?: boolean;
  logout?: any;
}

const defaultValues: IProps = {
  isAdmin: false,
  loggingOut: false,
  authenticated: false,
  logout: undefined,
};

const UserContext = React.createContext(defaultValues);

class UserProviderComponent extends Component<IProps> {
  render() {
    const { isAdmin, authenticated } = this.props;

    return (
      <UserContext.Provider
        value={{
          isAdmin,
          loggingOut: false,
          authenticated: !!authenticated,
          logout: this.props.logout,
          // authenticated,
          // loggingOut,
          // logout: this.logout.bind(this),
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;

export const UserProvider = connect(
  (state: AppState) => ({
    isAdmin: isAdmin(state),
    authenticated: !!state.user.user,
  }),
  { logout }
)(UserProviderComponent);
