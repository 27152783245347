// src/OktaSignInWidget.js

import React, { Component } from 'react';
import { OktaLogin } from './styles';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '@okta/okta-signin-widget/dist/css/okta-theme.css';
import { OktaLoginLanguages } from './lang';

const { REACT_APP_AUTH_ISSUER_ROOT } = process.env;
interface IProps {
  onSuccess: any;
  onError: any;
  lang: string;
}

export default class OktaSignInWidget extends Component<IProps> {
  public widget: any;
  constructor(props: IProps) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
  }
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    this.widget = new OktaSignIn({
      baseUrl: REACT_APP_AUTH_ISSUER_ROOT,
      language: this.props.lang,
      i18n: OktaLoginLanguages,
    });
    this.widget.renderEl({ el }, this.onSuccess, this.onError);
  }
  onSuccess(res: any) {
    this.props.onSuccess(res);
  }

  onError(e: any) {
    this.props.onError(e);
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <OktaLogin />;
  }
}
