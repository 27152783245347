import { createSelector } from 'reselect';
import { AppState } from '../';

export const selectTitle = (state: AppState) =>
  state.router.location.pathname === '/'
    ? 'route.dashboard'
    : state.router && !state.router.location.pathname.includes('editor')
      ? 'route.' + state.router.location.pathname.replace(/\//g, '')
      : 'route.default';

export const selectCustomTitle = createSelector(
  (state: AppState) => state.creativities.all,
  (state: AppState) => state.router.location.pathname,
  (creativities, pathname) => {
    if (!pathname) {
      return 'route.default';
    }

    if (!pathname.includes('editor')) {
      return '';
    }

    if (!(creativities && creativities.length)) {
      return 'route.default';
    }

    const creativity = creativities.find(
      creativity =>
        !!(creativity && creativity._id && pathname.includes(creativity._id))
    );

    if (!creativity) {
      return 'route.default';
    }

    return creativity.name;
  }
);

export const isEmergencyRoute = createSelector(
  (state: AppState) => state.router.location.pathname,
  pathname => (pathname.includes('emergency') ? true : false)
);
