import React, { PureComponent } from 'react';
import { BarCustom, BarCustomText } from './styles';

interface IProps {
  label: string;
}

export default class Bar extends PureComponent<IProps> {
  public render() {
    return (
      <BarCustom>
        <BarCustomText>{this.props.label}</BarCustomText>
      </BarCustom>
    );
  }
}
