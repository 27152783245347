import styled from 'styled-components';

interface IProps {
  active?: boolean;
}

export const Container = styled.div<IProps>`
  width: 100%;
  height: 100%
  cursor: ${props => (props.active ? 'grabbing' : 'default')};
  cursor:${props => (props.active ? '-webkit-grabbing' : '-webkit-default')};
`;
