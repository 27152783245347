import React, { PureComponent, ReactElement } from 'react';
import { Wrapper, TitleWrapper, HeaderWrapper, BodyWrapper } from './styles';

interface IProps {
  defaultTitleWrapper?: string;
  typeHeader?: string;
  typeBody?: string;
  typeTitle?: string;
  children?: ReactElement<any>[] | ReactElement<any>;
}

interface IState {
  titleWrapper?: string;
}

export default class Box extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      titleWrapper: this.props.defaultTitleWrapper,
    };
  }
  public render() {
    const { typeBody, typeHeader, typeTitle, children } = this.props;
    return (
      <Wrapper>
        <HeaderWrapper typeHeader={typeHeader}>
          <TitleWrapper typeTitle={typeTitle}>
            {' '}
            {this.state.titleWrapper}{' '}
          </TitleWrapper>
        </HeaderWrapper>
        <BodyWrapper typeBody={typeBody} className="boxBody">
          {children}
        </BodyWrapper>
      </Wrapper>
    );
  }
}
