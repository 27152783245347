import styled from 'styled-components';

export const Container = styled.div<{ background: string }>`
  display: flex;
  max-width: 450px;
  min-width: 395px;
  padding: 45px;
  border-radius: 4px;
  justify-content: flex-end;
  flex-direction: column;
`;
