import React, { PureComponent } from 'react';
import { CustomCheckbox } from './styles';
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox';

class Checkbox extends PureComponent<CheckboxProps> {
  render() {
    const {
      checked,
      onChange,
      disabled,
      value,
      children,
      className,
    } = this.props;
    return (
      <CustomCheckbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value}
        className={className}
      >
        {children}
      </CustomCheckbox>
    );
  }
}

export default Checkbox;
