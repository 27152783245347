import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 64px;
  width: 348px;
  padding: 0px 15px;
  margin-left: 20px;
  background: ${props => props.theme.colors.red};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.p`
  margin: 0;
  color: ${props => props.theme.colors.white};
  font-weight: ${props => props.theme.fontWeights.light};
  font-size: ${props => props.theme.sizes.M};
`;
