import styled from 'styled-components';
import { Icon } from 'antd';

export const CustomIcon = styled(Icon)`
  &.disabled {
    cursor: not-allowed;
    color: lightgrey;
  }
  &.redHover:hover {
    color: red;
  }
`;

export const PopOverText = styled.p`
  margin-bottom: 0.3em;
`;
