import React, { Component, forwardRef, ReactElement, RefObject } from 'react';
import { Container } from './styles';
import ReactDOM from 'react-dom';

interface IProps {
  innerRef: RefObject<any>;
  children: ReactElement<any>[] | ReactElement<any>;
}

interface IState {
  isScrolling: boolean;
  startX: number;
  startY: number;
  scrollLeft: number;
  scrollTop: number;
}

const withForwardedRef = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const handleRef = (props: any, ref: RefObject<P>) => (
    <Component {...props} innerRef={ref} />
  );
  return forwardRef(handleRef);
};

class ScrollDrag extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isScrolling: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
    };
  }

  onMouseDown = (e: React.MouseEvent): void => {
    const component = ReactDOM.findDOMNode(
      this.props.innerRef.current
    ) as Element;
    const componentPosition = component.getBoundingClientRect();
    this.setState({
      isScrolling: true,
      startX: e.pageX - componentPosition.left,
      startY: e.pageY - componentPosition.top,
      scrollLeft: component.scrollLeft,
      scrollTop: component.scrollTop,
    });
  };

  onMouseLeave = (): void => {
    this.setState({
      isScrolling: false,
    });
  };

  onMouseUp = (): void => {
    this.setState({
      isScrolling: false,
    });
  };

  onMouseMove = (e: React.MouseEvent): void => {
    const { startX, startY, isScrolling, scrollLeft, scrollTop } = this.state;
    if (!isScrolling) return;
    e.preventDefault();
    const component = ReactDOM.findDOMNode(
      this.props.innerRef.current
    ) as Element;
    const componentPosition = component.getBoundingClientRect();

    const x = e.pageX - componentPosition.left;
    const y = e.pageY - componentPosition.top;
    const distanceX = (x - startX) * 3;
    const distanceY = (y - startY) * 3;

    if (component) {
      component.scrollLeft = scrollLeft - distanceX;
      component.scrollTop = scrollTop - distanceY;
    }
  };

  render() {
    const { innerRef } = this.props;
    const { isScrolling } = this.state;
    return (
      <Container
        active={isScrolling}
        ref={innerRef}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
      >
        {this.props.children}
      </Container>
    );
  }
}

export default withForwardedRef(ScrollDrag);
