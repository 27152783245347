import styled from 'styled-components';

export const CustomDiv = styled.div`
  margin: 10px;
`;

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
`;

export const PInfo = styled.div`
  align-self: center;
  padding: 2rem;
  font-weight: ${props => props.theme.fontWeights.semiBold};
  font-size: ${props => props.theme.sizes.S};
`;
