import styled from 'styled-components';

export const ContainerLayout = styled.div``;

export const ContainerSection = styled.div`
  margin: 10px 100px;
`;

export const WrapperBodyBox = styled.div`
  display: table;
  margin: 0 auto;
  padding: 19px 0px;
`;

export const TextTooltip = styled.span`
  background-color: rgba(0, 0, 0, 0.75);
`;

export const ContainerSectionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TagButtonWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
`;

export const SectionRight = styled.div`
  float: right;
`;

export const TagText = styled.p`
  display: flex;
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.light};
  padding: 5px 0 5px 7px;
  margin: 0;
  color: ${props => props.theme.colors.white};
`;

export const TagBoldText = styled.p`
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 1px;
  padding: 5px 7px 5px 7px;
  margin: 0;
  color: ${props => props.theme.colors.white};
`;

export const Container = styled.div`
  list-style: none;
  display: grid;
  grid-gap: 20px;
  background-color: ${props => props.theme.colors.white};
  box-sizing: content-box;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0;
  padding: 20px 0px;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  justify-items: center;
`;

export const WrapperContainer = styled.div`
  width: 100%;
`;

export const WrapperText = styled.div`
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: ${props => props.theme.sizes.L};
  font-weight: ${props => props.theme.fontWeights.bold};
`;

export const WrapperButton = styled.div`
  margin: 0 auto;
  width: 50%;
  display: flex;
  justify-content: center;
  button {
    margin: 5px;
  }
`;

export const WrapperFilter = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
`;
