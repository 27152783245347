import React, { EventHandler } from 'react';

interface ElementExtraProps {
  style: {
    fontSize: number;
    height: number;
    width: number;
    color: string;
  };
}

export interface ElementProps<P = {}> {
  element: ElementExtraProps & P;
  zoom: number;
}

export class Element<P, S = {}, T = {}> extends React.Component<
  ElementProps<P> & T,
  S
> {
  getStyle() {
    const style = this.props.element.style;
    const zoom = this.props.zoom;

    return {
      ...style,
      fontSize: style.fontSize * zoom,
      height: style.height * zoom,
      width: style.width * zoom,
    };
  }
}
