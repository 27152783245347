import React, { PureComponent } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';
import { CustomDivGrey, CustomDivBodyGrey, ButtonContainer } from './styles';
import { Button, ListSelect } from '../../components';
import { Row, Col } from 'antd';

interface IProps {
  initialSelectedItems: IItem[];
  initialItems: IItem[];
  intl: InjectedIntl;
  onChange?: (selectedItems: IItem[]) => void;
}

interface IState {
  selectedItems: IItem[];
  items: IItem[];
}

interface IItem {
  id: string;
  title: string;
  subtitle?: string;
}

interface IListSelect {
  getActiveItems: () => any[];
  selectAll: () => void;
}

export class PanelsSelectionsComponent extends PureComponent<IProps, IState> {
  public listSelected?: IListSelect;
  public listSelect?: IListSelect;

  constructor(props: IProps) {
    super(props);
    this.state = {
      items: this.props.initialItems,
      selectedItems: this.props.initialSelectedItems,
    };
  }

  componentDidUpdate() {
    this.setState({
      items: this.props.initialItems,
      selectedItems: this.props.initialSelectedItems,
    });
  }

  reset = () => {
    this.setState({
      items: this.props.initialItems,
      selectedItems: this.props.initialSelectedItems,
    });
  };

  handleAddItems = () => {
    const activesItems =
      (this.listSelect && this.listSelect.getActiveItems()) || [];

    const selectedItems = [...this.state.selectedItems, ...activesItems];
    const items = [
      ...this.state.items.filter(item => !activesItems.includes(item)),
    ];

    this.setState({
      selectedItems,
      items,
    });

    this.props.onChange && this.props.onChange(selectedItems);
  };

  handleRemoveItems = () => {
    const activesItems =
      (this.listSelected && this.listSelected.getActiveItems()) || [];

    const items = [...this.state.items, ...activesItems];
    const selectedItems = [
      ...this.state.selectedItems.filter(item => !activesItems.includes(item)),
    ];

    this.setState({
      items,
      selectedItems,
    });

    this.props.onChange && this.props.onChange(selectedItems);
  };

  render() {
    return (
      <Row gutter={16}>
        <Col span={12}>
          <CustomDivGrey>
            <CustomDivBodyGrey>
              <ListSelect
                items={this.state.items}
                ref={(listSelect: ListSelect) => {
                  this.listSelect = listSelect;
                }}
              />
            </CustomDivBodyGrey>
          </CustomDivGrey>
          <ButtonContainer>
            <Button
              onClick={() => {
                this.listSelect && this.listSelect.selectAll();
              }}
              type="resetBlueWhite"
              button={this.props.intl.formatMessage({
                id: 'screenSelection.panels.button.selectAll',
              })}
            />
            <Button
              onClick={this.handleAddItems}
              type="grey"
              button={this.props.intl.formatMessage({
                id: 'screenSelection.panels.button.addSelect',
              })}
            />
          </ButtonContainer>
        </Col>
        <Col span={12}>
          <CustomDivGrey>
            <CustomDivBodyGrey>
              <ListSelect
                items={this.state.selectedItems}
                ref={(listSelected: ListSelect) => {
                  this.listSelected = listSelected;
                }}
              />
            </CustomDivBodyGrey>
          </CustomDivGrey>
          <Button
            onClick={this.handleRemoveItems}
            type="grey"
            button={this.props.intl.formatMessage({
              id: 'screenSelection.panels.button.removeSelection',
            })}
          />
        </Col>
      </Row>
    );
  }
}

export const PanelsSelections = injectIntl(PanelsSelectionsComponent);
