export const OktaLoginLanguages = {
  en: {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'User',
    'primaryauth.password.placeholder': 'Password',
    'primaryauth.submit': 'Sign in',
    'error.username.required': 'Please enter a username',
    'error.password.required': 'Please enter a password',
    remember: 'Remember me',
    needhelp: 'Need help signing in?',
    forgotpassword: 'Forgot password?',
    'password.reset': ' ',
    'password.forgot.email.or.username.placeholder': 'Email or username',
    'password.forgot.sendEmail': 'Reset via Email',
    help: 'Help',
    goback: 'Back to sign in',
    'errors.E0000004': 'Sign in failed',
    'password.forgot.emailSent.title': 'Email sent!',
    'password.forgot.emailSent.desc':
      'Email has been sent to {0} with instructions on resetting your password.',
  },
  fr: {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'Connexion',
    'primaryauth.password.placeholder': 'Mot de passe',
    'primaryauth.submit': 'Connecter',
    'error.username.required': "S'il vous plaît, entrer un nom d'utilisateur",
    'error.password.required': "S'il vous plaît, entrez un mot de passe",
    remember: 'Mémoriser info',
    needhelp: "Besoin d'aide pour vous connecter?",
    forgotpassword: 'Mot de passe oublié?',
    'primaryauth.password.tooltip': ' ',
    'primaryauth.username.tooltip': ' ',
    'password.reset': ' ',
    'password.forgot.email.or.username.placeholder':
      "Email ou nom d'utilisateur",
    'password.forgot.sendEmail': 'Réinitialiser par e-mail',
    help: "L'aide",
    'password.forgot.email.or.username.tooltip': ' ',
    goback: 'Retour à la connexion',
    'errors.E0000004': 'Échec de la connexion',
    'password.forgot.emailSent.title': 'Email envoyé',
    'password.forgot.emailSent.desc':
      'Un email a été envoyé a {0} avec les instructions pour réinitialiser votre mot de passe',
  },
  es: {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'User',
    'primaryauth.password.placeholder': 'Password',
    'primaryauth.submit': 'Sign in',
    'error.username.required': 'Please enter a username',
    'error.password.required': 'Please enter a password',
    needhelp: 'Need help signing in?',
    remember: 'Remember me',
    forgotpassword: 'Forgot password?',
    'password.reset': ' ',
    'password.forgot.email.or.username.placeholder': 'Email or username',
    'password.forgot.sendEmail': 'Reset via Email',
    help: 'Help',
    goback: 'Back to sign in',
    'errors.E0000004': 'Sign in failed',
    'password.forgot.emailSent.title': 'Email sent!',
    'password.forgot.emailSent.desc':
      'Email has been sent to {0} with instructions on resetting your password.',
  },
  de: {
    'primaryauth.title': ' ',
    'primaryauth.username.placeholder': 'User',
    'primaryauth.password.placeholder': 'Password',
    'primaryauth.submit': 'Sign in',
    'error.username.required': 'Please enter a username',
    'error.password.required': 'Please enter a password',
    needhelp: 'Need help signing in?',
    remember: 'Remember me',
    forgotpassword: 'Forgot password?',
    'password.reset': ' ',
    'password.forgot.email.or.username.placeholder': 'Email or username',
    'password.forgot.sendEmail': 'Reset via Email',
    help: 'Help',
    goback: 'Back to sign in',
    'errors.E0000004': 'Sign in failed',
    'password.forgot.emailSent.title': 'Email sent!',
    'password.forgot.emailSent.desc':
      'Email has been sent to {0} with instructions on resetting your password.',
  },
};
