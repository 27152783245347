import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { InjectedIntl, injectIntl } from 'react-intl';
import { CustomDatePicker, CustomPicker, Date, Time } from './styles';
import { AppState } from '../../redux/modules';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeDE from 'antd/es/date-picker/locale/de_DE';
import localeFR from 'antd/es/date-picker/locale/fr_FR';

interface IProps {
  type?: string;
  onPress?: (date: moment.Moment, dateString: string) => void;
  value?: moment.Moment;
  id?: string;
  disabled?: boolean;
  disabledDate?: (date: moment.Moment) => boolean;
  intl?: InjectedIntl;
  placeholder?: string;
  language?: string;
}

export class PickerComponent extends Component<IProps> {
  renderLocale() {
    switch (this.props.language) {
      case 'es':
        return localeEs;
      case 'fr':
        return localeFR;
      case 'de':
        return localeDE;
      case 'en':
      default:
        return localeEN;
    }
  }
  public render() {
    const { type, onPress, value, id, disabled, disabledDate } = this.props;
    if (type === 'date') {
      return (
        <CustomDatePicker>
          <Date
            key={id}
            disabled={disabled}
            disabledDate={disabledDate}
            defaultValue={value}
            onChange={onPress}
            allowClear={true}
            placeholder={this.props.intl!.formatMessage({ id: 'ddmmyy' })}
            format={'DD/MM/YY'}
            locale={this.renderLocale()}
          />
        </CustomDatePicker>
      );
    }
    return (
      <CustomPicker>
        <Time
          key={id}
          disabled={disabled}
          allowClear={true}
          defaultValue={value}
          onChange={onPress}
          placeholder={'HH:MM'}
          format={'HH:mm'}
          minuteStep={15}
        />
      </CustomPicker>
    );
  }
}

export const Picker = connect(
  (state: AppState) => ({
    language: state.locale.instance.language,
  }),
  {}
)(injectIntl<any>(PickerComponent));
