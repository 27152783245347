import { ICreativity, ISlide, IElement } from './types';

export const validate = (creativity: ICreativity) => {
  const { slides } = creativity;

  return slides.reduce((errors, slide) => {
    return [...errors, ...validateSlide(slide)];
  }, []);
};

const validateSlide = (slide: ISlide) => {
  const { elements } = slide;

  return elements.reduce(
    (errors, element) => [...errors, ...validateElement(element)],
    []
  );
};

const validateElement = (element: IElement) => {
  if (element.notEmpty && !(element.content || element.url)) {
    return ['editor.error.notEmpty.description'];
  }

  return [];
};
