import styled from 'styled-components';

export const OktaLogin = styled.div`
  #okta-sign-in.auth-container {
    &.main-container {
      border: none;
      box-shadow: none;
      margin-top: 0;
    }
    &.o-form {
      color: red;
    }
    input[type='submit'] {
      background: ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.white};
      border: none;
      box-shadow: none;
    }
  }
  #okta-sign-in.auth-container .button-primary {
    background: ${props => props.theme.colors.blue};
    color: ${props => props.theme.colors.white};
    border: none;
    box-shadow: none;
    :hover {
      background: ${props => props.theme.colors.vividBlueLight};
    }
  }
  #okta-sign-in .auth-header {
    border-bottom: none;
    padding: 0;
  }
`;
