import React, { PureComponent } from 'react';
import { CustomTab } from './styles';
import { TabPaneProps } from 'antd/lib/tabs';

interface IProps extends TabPaneProps {
  onClick?: any;
}

export default class Tab extends PureComponent<IProps> {
  render() {
    return <CustomTab {...this.props}> {this.props.children} </CustomTab>;
  }
}
