import styled from 'styled-components';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

export const CustomModal = styled(<any>Modal)<ModalProps>`
  .ant-modal-content {
    .ant-modal-header {
      background: ${props => props.theme.colors.superlightGrey};
      padding: 6px 20px;
    }
    .ant-modal-close {
      .ant-modal-close-x {
        line-height: 40px;
      }
    }
  }
`;
