import { IUser, UserAction } from './types';
import { accessItem, idItem } from '../../../utils/auth';

export interface IUserState {
  user?: IUser;
  message?: string;
  error?: any;
  isFetching: boolean;
  isFinished: boolean;
  tokenVerifyError?: string;
}

const initialState: IUserState = {
  isFetching: true,
  isFinished: false,
};

export const user = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case 'USER/PASSWORD_CHANGE_REQUEST':
    case 'USER/LOGIN_REQUEST':
    case 'USER/VERIFY_REQUEST':
      return {
        ...state,
        isFetching: true,
      };
    case 'USER/LOGIN_SUCCESS':
      const { idToken, accessToken } = action.data;
      localStorage.setItem(idItem, idToken || '');
      localStorage.setItem(accessItem, accessToken || '');

      return {
        ...state,
        error: undefined,
        isFetching: false,
        tokenVerifyError: undefined,
      };
    case 'USER/LOGOUT':
      localStorage.removeItem(idItem);
      localStorage.removeItem(accessItem);

      return {
        ...state,
        user: undefined,
      };
    case 'USER/VERIFY_SUCCESS':
      return {
        ...state,
        user: action.data,
        isFinished: true,
        isFetching: false,
        tokenVerifyError: undefined,
      };
    case 'USER/VERIFY_FAILURE':
      localStorage.removeItem(idItem);
      localStorage.removeItem(accessItem);

      return {
        ...state,
        tokenVerifyError: action.error,
        isFinished: true,
        isFetching: false,
        user: undefined,
      };
    case 'USER/PASSWORD_CHANGE_SUCCESS':
      return {
        ...state,
        message: action.data.message,
      };
    case 'USER/PASSWORD_CHANGE_FAILURE':
    case 'USER/LOGIN_FAILURE':
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
