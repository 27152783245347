import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { TagButton, Modal, Button } from '../../components';
import { message } from 'antd';
import {
  ContainerLayout,
  ContainerSection,
  ContainerSectionRow,
  TagText,
  TagBoldText,
  Container,
  WrapperContainer,
  WrapperButton,
  WrapperText,
  WrapperFilter,
} from './styles';
import {
  AppState,
  ICreativity,
  Filter,
  selectFilteredCreativities,
  deleteCreativity,
  Status,
  fetchCreativities,
  changeSortValue,
} from '../../redux/modules';
import {
  StatusFilter,
  FilterAction,
  setFilter,
} from '../../redux/modules/filters';
import StatusFilters from './StatusFilter';
import TemplateList from './TemplateList';
import { UserConsumer } from '../../providers';
import CreativeCard from './CreativeCard';
import SortCreativities from './SortCreativities';

const { REACT_APP_MULTISTATE_FILTER } = process.env;

interface IProps {
  creativities: ICreativity[];
  currentStatus?: StatusFilter | StatusFilter[];
  setFilter: (filter: Filter, value: StatusFilter) => FilterAction;
  intl: InjectedIntl;
  deleteCreativity?: any;
  id: string;
  status: Status;
  actions?: React.ReactNode[] | undefined;
  openModalDelete?: (creativeId: string) => void;
  selected?: boolean | undefined;
  fetchCreativities?: any;
  sort?: any;
  changeSortValue?: any;
}

interface IState {
  visibleDeleteModal: boolean;
  creativeToDelete: string;
}

class Creative extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      visibleDeleteModal: false,
      creativeToDelete: '',
    };

    this.getFilterCopy = this.getFilterCopy.bind(this);
    this.handleCloseButton = this.handleCloseButton.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.handleDoneDeleteModal = this.handleDoneDeleteModal.bind(this);
    this.handleCancelDeleteModal = this.handleCancelDeleteModal.bind(this);
  }
  componentDidUpdate(prevProps: IProps) {
    const { sort, fetchCreativities } = this.props;
    const sortObj = {
      key: sort,
      status: 'All',
    };
    if (sort !== prevProps.sort) {
      fetchCreativities(sortObj);
    }
  }

  componentDidMount() {
    const { sort, fetchCreativities } = this.props;
    const sortObj = {
      key: sort,
      status: 'All',
    };
    fetchCreativities(sortObj);
  }

  getFilterCopy = (status: StatusFilter) => {
    switch (status) {
      case 'Completed': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.completed',
        });
      }
      case 'On Going': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.ongoing',
        });
      }
      case 'Scheduled': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.scheduled',
        });
      }
      case 'Pending Validation': {
        return this.props.intl.formatMessage({
          id: 'creativeList.tag.pending',
        });
      }
      case 'Draft': {
        return this.props.intl.formatMessage({ id: 'creativeList.tag.drafts' });
      }
      default:
        return this.props.intl.formatMessage({ id: 'creativeList.tag.all' });
    }
  };

  handleCloseButton = () => {
    this.props.setFilter(Filter.STATUS, StatusFilter.ALL);
  };

  openModalDelete(creativeId: string) {
    this.setState({ visibleDeleteModal: true, creativeToDelete: creativeId });
  }

  closeModalDelete() {
    this.setState({ visibleDeleteModal: false });
  }

  handleDoneDeleteModal() {
    this.props.deleteCreativity(this.state.creativeToDelete);
    this.closeModalDelete();
    return message.info(
      this.props.intl.formatMessage({
        id: 'creativeList.deleteCreative.message',
      })
    );
  }

  handleCancelDeleteModal() {
    this.closeModalDelete();
  }

  render() {
    const { creativities, intl, actions } = this.props;
    const { visibleDeleteModal } = this.state;

    const defaultCreative = creativities.find(creative => !!creative.selected);
    const creatives = creativities.filter(creative => !creative.selected);

    return (
      <Fragment>
        <Modal
          title={intl!.formatMessage({
            id: 'actions.modalDeleteTitle',
          })}
          handleCustomOk={this.handleDoneDeleteModal}
          handleCustomCancel={this.handleCancelDeleteModal}
          visible={visibleDeleteModal}
          noFooter={true}
        >
          <WrapperContainer>
            <WrapperText>
              <FormattedMessage id="actions.modalDeleteText" />
            </WrapperText>
            <WrapperButton>
              <Button
                type="whiteBlue"
                button={intl!.formatMessage({
                  id: 'actions.modalCancel',
                })}
                onClick={this.handleCancelDeleteModal}
              />
              <Button
                button={intl!.formatMessage({
                  id: 'actions.deleteModalConfirm',
                })}
                onClick={this.handleDoneDeleteModal}
              />
            </WrapperButton>
          </WrapperContainer>
        </Modal>
        <ContainerLayout className="dashboard">
          <ContainerSection>
            <ContainerSectionRow>
              <WrapperFilter>
                <StatusFilters
                  mode={REACT_APP_MULTISTATE_FILTER ? 'multiple' : undefined}
                />
                <SortCreativities />
              </WrapperFilter>
              <TemplateList />
            </ContainerSectionRow>
            {this.props.currentStatus &&
              this.props.currentStatus !== StatusFilter.ALL &&
              (typeof this.props.currentStatus === 'string' ? (
                <TagButton onClick={this.handleCloseButton}>
                  <TagText>
                    <FormattedMessage id={'creativeList.tag.showing'} />
                  </TagText>
                  <TagBoldText>
                    {this.getFilterCopy(this.props.currentStatus)}
                  </TagBoldText>
                </TagButton>
              ) : (
                <TagButton onClick={this.handleCloseButton}>
                  <TagText>
                    <FormattedMessage id={'creativeList.tag.showing'} />
                  </TagText>
                  <TagBoldText>
                    {this.props.currentStatus
                      .map((status: StatusFilter) => this.getFilterCopy(status))
                      .join(', ')}
                  </TagBoldText>
                </TagButton>
              ))}
            <Container>
              {defaultCreative && (
                <UserConsumer key={defaultCreative._id}>
                  {user => (
                    <CreativeCard
                      card={defaultCreative}
                      actions={actions}
                      status={defaultCreative.status}
                      isAdmin={!!user.isAdmin}
                      openModalDelete={this.openModalDelete}
                      id={defaultCreative._id}
                      selected={defaultCreative.selected}
                    />
                  )}
                </UserConsumer>
              )}
              {(creatives || []).map(card => (
                <UserConsumer key={card._id}>
                  {user => (
                    <CreativeCard
                      card={card}
                      actions={actions}
                      status={card.status}
                      isAdmin={!!user.isAdmin}
                      openModalDelete={this.openModalDelete}
                      id={card._id}
                      selected={card.selected}
                    />
                  )}
                </UserConsumer>
              ))}
            </Container>
          </ContainerSection>
        </ContainerLayout>
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      creativities: selectFilteredCreativities(Filter.STATUS)(state),
      currentStatus: state.filters.filters[Filter.STATUS],
      sort: state.creativities.sort,
    };
  },
  {
    setFilter,
    deleteCreativity,
    fetchCreativities,
    changeSortValue,
  }
)(injectIntl<any>(Creative));
