import { AppState } from '../';

export const selectTemplateList = (state: AppState) =>
  state.templates.all.reduce(
    (list, template) =>
      template.emergency
        ? list
        : [
            ...list,
            {
              key: template._id,
              label: template.name,
              value: template._id,
              favourite: template.favourite,
            },
          ],
    []
  );

export const selectEmergenciesByType = (state: AppState, type: string) => {
  return state.templates.all.filter(
    template => template.emergency && template.emergencycategory === type
  );
};

export const selectTemplateById = (id: string) => (state: AppState) => {
  return state.templates.all.find(template => template._id === id);
};
