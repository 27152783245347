import styled from 'styled-components';
import { Spin } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

export const LoadingContainer = styled.div`
  padding: 10px 15px;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.darkBlue};
  font-weight: ${props => props.theme.fontWeights.regular};
  font-size: ${props => props.theme.sizes.M};
  border-radius: 5px;
  box-shadow: 1px 1px 10px ${props => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
`;

export const Spinner = styled(Spin)`
  margin-top: 4px;
`;

export const Text = styled.p`
  margin: 0;
  padding-left: 10px;
`;
