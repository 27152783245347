import styled from 'styled-components';

export const BarCustom = styled.div`
  margin: 20px 0px 0px;
  height: 1px;
  background: ${props => props.theme.colors.superlightGrey};
  border-bottom: 50px solid ${props => props.theme.colors.superlightGrey};
`;

export const BarCustomText = styled.p`
  color: ${props => props.theme.colors.blue};
  font-size: ${props => props.theme.sizes.XL};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 4px;
  padding: 25px;
`;
