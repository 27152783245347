import styled from 'styled-components';
import { colors, sizes, fontWeights } from '../../../theme';

interface IStatusProps {
  statusColor: string;
}

interface ITitleProps {
  active?: boolean;
}

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Tabs = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
`;

export const TabName = styled.div`
  width: auto;
  margin-right: 20px;
  cursor: pointer;
`;

export const Title = styled.p<ITitleProps>`
  color: ${({ active }) => (active ? colors.black : colors.megaDarkGrey)};
  font-size: ${sizes.M};
  font-weight: ${({ active }) =>
    active ? fontWeights.bold : fontWeights.light};
`;

export const Small = styled.p`
  color: ${colors.grey};
  font-size: ${sizes.S};
  font-weight: ${fontWeights.bold};
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusStyled = styled.p<IStatusProps>`
  color: ${colors.white};
  font-size: ${sizes.S};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  background-color: ${({ statusColor }) => statusColor};
  padding: 5px 10px;
  border-radius: 4px;
`;
