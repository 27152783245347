import React from 'react';
import * as logos from '../../assets/logos';
import { Container, Logo, Name } from './styles';

interface IProps {
  logo?: string;
  name?: string;
}
const defaultLogo = (logos as any).defaultLogo;

const InstanceName = ({ name, logo = defaultLogo }: IProps) => {
  return (
    <Container>
      <Logo src={logo} />
      <Name>{name || ''}</Name>
    </Container>
  );
};

export default InstanceName;
