import React, { Component } from 'react';
import { ELEMENTS } from '../../../../../utils';

import {
  TextElement,
  ImageElement,
  VideoElement,
  WeatherElement,
  QrElement,
} from './elements';

import { Container } from './styles';
import LocationElement from './elements/LocationElement';

interface IProps {
  element: any;
  zoom: number;
  activeElement: number;
  index: number;
  playing: boolean;
}

export default class Element extends Component<IProps> {
  getStyle() {
    const { style } = this.props.element;
    const { zoom } = this.props;

    return {
      top: style.top * zoom,
      left: style.left * zoom,
      height: style.height * zoom,
      width: style.width * zoom,
      zIndex: style.zIndex,
    };
  }

  renderElementType() {
    const { element, zoom, playing } = this.props;

    switch (element.type) {
      case ELEMENTS.TEXT:
        return <TextElement element={element} zoom={zoom} />;
      case ELEMENTS.IMAGE:
        return <ImageElement element={element} zoom={zoom} />;
      case ELEMENTS.VIDEO:
        element.playing = playing;
        return <VideoElement element={element} zoom={zoom} />;
      case ELEMENTS.QR:
        return <QrElement element={element} zoom={zoom} />;
      case ELEMENTS.WEATHER:
        return <WeatherElement element={element} zoom={zoom} />;
      case ELEMENTS.LOCATION:
        return <LocationElement element={element} zoom={zoom} />;
      default:
        return null;
    }
  }

  render() {
    const { activeElement, index } = this.props;
    return (
      <Container style={this.getStyle()} active={activeElement === index}>
        {this.renderElementType()}
      </Container>
    );
  }
}
