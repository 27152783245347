import React, { PureComponent } from 'react';
import { Container, LoadingContainer, Text, Spinner } from './styles';

export default class LoadingMessage extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <Container>
        <LoadingContainer>
          <Spinner />
          <Text>{children}</Text>
        </LoadingContainer>
      </Container>
    );
  }
}
