import styled from 'styled-components';
import { Divider } from 'antd';

interface ISubheaderProps {
  emergency?: boolean;
}
export const Wrapper = styled.div`
  padding: 0 25px;
  display: flex;
`;

export const Subheader = styled.section``;

export const Container = styled.div`
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 3px 15px 0 rgba(0,0,0,0.1);}
`;

export const ContainerGrey = styled(Container)`
  background-color: ${props => props.theme.colors.lightGrey};
`;

export const LinkHeader = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.superDarkGrey};
`;
export const Img = styled.img<any>`
  height: 28px;
  width: auto;
  margin: 18px 0;
`;
export const Listheader = styled.div`
  display: flex;
  list-style: none;
  justify-content: flex-end;
  height: 64px;
  align-items: center;
  margin-bottom: 0;
`;

export const ListItem = styled.span`
  text-decoration: none;
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.M};
  padding: 24px;
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 16px;
`;

export const ListDivider = styled(Divider)`
  background-color: ${props => props.theme.colors.superDarkGrey};
  margin: 0 24px;
  height: 35%;
`;

export const HeaderTitle = styled.h1<ISubheaderProps>`
  color: ${props =>
    props.emergency ? props.theme.colors.red : props.theme.colors.black};
  width: 100%;
  font-size: 35px;
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 43px;
  padding: 25px 0 20px 125px;
  margin: 0px;
`;
