import React from 'react';
import moment from 'moment-timezone';
import URI from 'urijs';
const query = URI((document as any).location.toString()).query(true);
let hour: any =
  moment((query as any).date).format('HH') || moment().format('HH');

let daynight = 'night';

export type Condition =
  | 'clear'
  | 'partly cloudy'
  | 'drizzle'
  | 'haze'
  | 'sand'
  | 'ash'
  | 'squalls'
  | 'unknown'
  | 'chance';
interface IProps {
  name: Condition;
}

//TODO: Revisar API de weather underground para asignar automáticamente horas de amanecer y ocaso
if (hour >= 7 && hour < 21) {
  daynight = 'day';
}

const getName = (name: string) => {
  switch (name) {
    case 'clear':
      if (daynight === 'day') {
        return 'day-sunny';
      } else {
        return 'night-clear';
      }
    case 'partly cloudy':
      return 'cloud';
    case 'drizzle':
      return 'showers';
    case 'haze':
      return 'day-haze';
    case 'sand':
      return 'sandstorm';
    case 'ash':
      return 'volcano';
    case 'squalls':
      return 'cloudy-gusts';
    case 'unknown':
      return 'na';
    case 'chance':
      return 'thunderstorm';
    default:
      return name;
  }
};

export const WeatherIcon = (props: IProps) => (
  <i className={`wi wi-${getName(props.name)}`} />
);
