import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import Button from '../../components/Button';
import { CustomModal } from './styles';

interface IProps {
  visible: boolean;
  handleCustomOk?: () => void;
  handleCustomCancel?: () => void;
  title: string;
  intl: InjectedIntl;
  noFooter?: boolean;
}

interface IState {
  visible: boolean;
}

class Modal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: this.props.visible };
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({ visible: nextProps.visible });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    if (this.props.handleCustomOk) {
      this.props.handleCustomOk();
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    if (this.props.handleCustomCancel) {
      this.props.handleCustomCancel();
    }
  };

  public render() {
    const { title, intl, noFooter } = this.props;
    const { visible } = this.state;
    return (
      <div>
        <div>
          <CustomModal
            title={title}
            visible={visible}
            onOk={this.handleOk}
            width={700}
            onCancel={this.handleCancel}
            footer={
              !noFooter && [
                <Button
                  key="submit"
                  type="primary"
                  button={intl.formatMessage({
                    id: 'modal.footer.buttonOk',
                  })}
                  onClick={this.handleOk}
                >
                  <FormattedMessage id="modal.footer.buttonSubmit" />
                </Button>,
              ]
            }
          >
            {this.props.children}
          </CustomModal>
        </div>
      </div>
    );
  }
}

export default injectIntl(Modal);
