import {
  Filter,
  StatusFilter,
  GroupFilter,
  SetFilterAction,
  SetFilterActionCreator,
} from './types';

export const setFilter: SetFilterActionCreator = (
  filter: Filter,
  value: StatusFilter | GroupFilter
): SetFilterAction => ({
  type: 'FILTER/SET_FILTER',
  filter,
  value,
});
