import React, { Component } from 'react';
import { CustomTabsPanel } from './styles';

interface IProps {
  defaultActiveKey?: string;
  onChange?: any;
}

export default class Tab extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.renderTabBar = this.renderTabBar.bind(this);
  }

  renderTabBar(props: IProps, DefaultTabBar: typeof Component) {
    return <DefaultTabBar {...props} />;
  }

  render() {
    return (
      <CustomTabsPanel
        {...this.props}
        renderTabBar={this.renderTabBar}
        defaultActiveKey={this.props.defaultActiveKey || '1'}
      />
    );
  }
}
