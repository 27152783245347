import styled from 'styled-components';
import LinkStyle from '../../components/Link';
import { Text, Link } from '../../components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  justify-content: center;
  align-items: center;
`;

export const RightContainer = styled.div<{ background: string }>`
  background: url(${props => props.background}) center;
  background-size: cover;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Login = styled(LinkStyle)``;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ErrorFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: 30px;
`;

export const SubmitButton = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  margin-left: 30px;
`;

export const MessageBox = styled(Text)`
  background: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: ${props => props.theme.sizes.S};
  margin-bottom: 20px;
`;

export const ButtonLink = styled(Link)`
  background: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 17px 30px;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

export const ErrorMessage = styled(Text)`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.sizes.M};
  font-weight: bold;
`;
