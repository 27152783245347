import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Element } from '../Element';

import { Text } from '../styles';

interface ElementExtraProps {
  content: string;
}

export default class TextElement extends Element<ElementExtraProps> {
  render() {
    return (
      <Text style={this.getStyle()}>
        {this.props.element.content ? (
          this.props.element.content
        ) : (
          <FormattedMessage id={'editor.empty'} />
        )}
      </Text>
    );
  }
}
