import styled from 'styled-components';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 22px;
`;

export const CustomInput = styled(<any>Input)<InputProps>`
  width: 75%;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.sizes.S};
  font-weight: ${props => props.theme.fontWeights.light};
  padding: 20px 11px;
`;
