import React, { Component } from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { CheckboxGroup } from '../../../../components';
import { Radio } from 'antd';
import {
  selectVisibilityWeekday,
  updateVisibilityWeekdays,
  AppState,
  Status,
} from '../../../../redux/modules';
import { RadioButtonsContainer, WeekDayDivider } from '../styles';

interface IProps {
  creativityId: string;
  checkedList?: number[];
  updateVisibilityWeekdays?: (id: string, checkedList: number[]) => void;
  intl?: InjectedIntl;
  status: Status;
  allSelected?: boolean;
}

const SOME_SELECTED = 2;
const ALL_SELECTED = 1;

interface IState {
  selection: number;
}

const options = [
  { label: <FormattedMessage id="weekdayselector.monday" />, value: 2 },
  { label: <FormattedMessage id="weekdayselector.tuesday" />, value: 3 },
  { label: <FormattedMessage id="weekdayselector.wednesday" />, value: 4 },
  { label: <FormattedMessage id="weekdayselector.thursday" />, value: 5 },
  { label: <FormattedMessage id="weekdayselector.friday" />, value: 6 },
  { label: <FormattedMessage id="weekdayselector.saturday" />, value: 7 },
  { label: <FormattedMessage id="weekdayselector.sunday" />, value: 1 },
];

class WeekDaySelector extends Component<IProps> {
  state: IState = {
    selection: ALL_SELECTED,
  };

  componentDidMount() {
    this.setState({
      selection: this.props.allSelected ? ALL_SELECTED : SOME_SELECTED,
    });
  }

  onChange = (checkedList: number[]) => {
    this.props.updateVisibilityWeekdays!(this.props.creativityId, checkedList);
  };

  onCheckAllChange = () => {
    this.setState({
      selection: ALL_SELECTED,
    });
    this.props.updateVisibilityWeekdays!(this.props.creativityId, [
      2,
      3,
      4,
      5,
      6,
      7,
      1,
    ]);
  };

  onCheckSpecificChange = () => {
    this.setState({
      selection: SOME_SELECTED,
    });
    this.props.updateVisibilityWeekdays!(this.props.creativityId, [
      2,
      3,
      4,
      5,
      6,
      7,
      1,
    ]);
  };

  render() {
    const { status, checkedList } = this.props;
    const radioStyle = {
      height: '30px',
      lineHeight: '22px',
      fontSize: '14px',
    };
    return (
      <>
        <RadioButtonsContainer value={this.state.selection}>
          <Radio
            disabled={
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
            value={ALL_SELECTED}
            style={radioStyle}
            onChange={this.onCheckAllChange}
          >
            <FormattedMessage id="weekdayselector.all" />
          </Radio>
          <Radio
            disabled={
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
            value={SOME_SELECTED}
            style={radioStyle}
            onChange={this.onCheckSpecificChange}
          >
            <FormattedMessage id="weekdayselector.specificDays" />
          </Radio>
          {this.state.selection === SOME_SELECTED ? (
            <CheckboxGroup
              disabled={
                status === Status.PUBLISHED ||
                status === Status.PENDING_VALIDATION
              }
              options={options}
              value={checkedList}
              onChange={this.onChange}
            />
          ) : (
            <WeekDayDivider />
          )}
        </RadioButtonsContainer>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => {
    const checkedList = selectVisibilityWeekday(state, props.creativityId);

    return {
      allSelected: !checkedList || checkedList.length === options.length,
      checkedList,
    };
  },
  { updateVisibilityWeekdays }
)(injectIntl<any>(WeekDaySelector));
