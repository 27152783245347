import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

export const CustomCheckboxGroup = styled(Checkbox.Group)<CheckboxGroupProps>`
  font-family: 'Montserrat';
  margin: 5px;
  width: 100%;
  font-size: ${props => props.theme.sizes.XS};
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.blue};
  }
`;

export const CheckBoxGroupContainer = styled.div`
  margin-top: 15px;
  height: auto;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;
