import update from 'immutability-helper';
import { CreativityAction, ICreativity, IElement } from './types';

export interface ICreativityState {
  all: ICreativity[];
  isFetching?: boolean;
  error?: any;
  activeSlide: number;
  activeElement: number;
  activeElementError?: string;
  sort?: string;
}

const initialState: ICreativityState = {
  all: [],
  isFetching: false,
  error: null,
  activeSlide: 0,
  activeElement: 0,
  sort: '',
};

const findSources = (jobs: any[], el: IElement) => {
  if (el.type === 'streaming' || (el.type === 'video' && !el.sources)) {
    const job = jobs.find(
      j => j && j.url === el.url && j.status === 'complete'
    );

    return job ? job.sources : null;
  }
};

export const creativities = (
  state = initialState,
  action: CreativityAction
) => {
  switch (action.type) {
    case 'CREATIVITIES/FETCH_REQUEST':
    case 'CREATIVITIES/FETCH_ALL_REQUEST':
    case 'CREATIVITIES/ADD_REQUEST':
    case 'CREATIVITIES/UPDATE_REQUEST':
    case 'CREATIVITIES/FETCH_JOBS_FAILURE':
    case 'CREATIVE/SET_DEFAULT_REQUEST':
    case 'CREATIVE/CHANGE_STATUS_REQUEST': {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case 'CREATIVITIES/RESET_ERROR': {
      return {
        ...state,
        error: false,
      };
    }
    case 'CREATIVITIES/FETCH_FAILURE':
    case 'CREATIVITIES/FETCH_ALL_FAILURE':
    case 'CREATIVITIES/ADD_FAILURE':
    case 'CREATIVITIES/UPDATE_FAILURE':
    case 'CREATIVITIES/DELETE_FAILURE':
    case 'CREATIVE/SET_DEFAULT_FAILURE':
    case 'CREATIVE/CHANGE_STATUS_FAILURE': {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }
    case 'CREATIVITIES/DELETE_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        all: state.all.filter(c => c._id !== action.id),
      };
    }
    case 'CREATIVITIES/FETCH_SUCCESS': {
      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.data._id),
          {
            ...state.all.find(creativity => creativity._id === action.data._id),
            ...action.data,
          },
        ],
        isFetching: false,
      };
    }
    case 'CREATIVITIES/FETCH_ALL_SUCCESS': {
      return {
        ...state,
        all: action.data,
        isFetching: false,
      };
    }
    case 'CREATIVITIES/ADD_SUCCESS': {
      return {
        ...state,
        all: [...state.all, action.data],
        isFetching: false,
      };
    }
    case 'CREATIVITIES/UPDATE_SUCCESS': {
      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.data._id),
          action.data,
        ],
        isFetching: false,
      };
    }
    case 'CREATIVITIES/UPDATE_ELEMENT_PROP': {
      const creativity = state.all.find(
        creativity => creativity._id === action.id
      );

      return creativity
        ? {
            ...state,
            all: [
              ...state.all.filter(creativity => creativity._id !== action.id),
              update(creativity, {
                slides: {
                  [state.activeSlide]: {
                    elements: {
                      [state.activeElement]: {
                        [action.prop]: { $set: action.value },
                      },
                    },
                  },
                },
              }),
            ],
          }
        : state;
    }
    case 'CREATIVITIES/CHANGE_SELECTION': {
      if (action.section === 'slide') {
        return {
          ...state,
          activeSlide: action.value,
        };
      }
      return {
        ...state,
        activeElement: action.value,
      };
    }
    case 'CREATIVITIES/UPDATE_TITLE': {
      if (action.section === 'slide') {
        const creativity = state.all.find(
          creativity => creativity._id === action.id
        );

        return creativity
          ? {
              ...state,
              all: [
                ...state.all.filter(creativity => creativity._id !== action.id),
                update(creativity, {
                  slides: {
                    [state.activeSlide]: {
                      elements: {
                        [state.activeElement]: {
                          name: { $set: action.name },
                        },
                      },
                    },
                  },
                }),
              ],
            }
          : state;
      }

      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.id),
          {
            ...state.all.find(creativity => creativity._id === action.id),
            name: action.name,
          },
        ],
      };
    }
    case 'CREATIVITIES/UPDATE_VISIBILITY': {
      let dates = {};

      if (action.data.rules != undefined) {
        let startDate = action.data.rules.find(
          rule => rule.type === 'date' && rule.comparator === 'gte'
        );
        let endDate = action.data.rules.find(
          rule => rule.type === 'date' && rule.comparator === 'lte'
        );
        dates = {
          startDate: startDate ? startDate.value : '',
          endDate: endDate ? endDate.value : '',
        };
      }

      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.id),
          {
            ...state.all.find(creativity => creativity._id === action.id),
            visibility: action.data,
            ...dates,
          },
        ],
      };
    }
    case 'CREATIVITIES/UPDATE_PLAYERS': {
      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.id),
          {
            ...state.all.find(creativity => creativity._id === action.id),
            players: action.players,
          },
        ],
      };
    }
    case 'CREATIVITIES/UPDATE_GROUPS': {
      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.id),
          {
            ...state.all.find(creativity => creativity._id === action.id),
            groups: action.groups,
          },
        ],
      };
    }
    case 'CREATIVITIES/UPDATE_PROP': {
      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== action.id),
          {
            ...state.all.find(creativity => creativity._id === action.id),
            ...action.update,
          },
        ],
      };
    }
    case 'EMERGENCIES/ADD_SUCCESS': {
      return {
        ...state,
        all: [...state.all, action.data],
        isFetching: false,
      };
    }
    case 'CREATIVITIES/FETCH_JOBS_SUCCESS': {
      const { id, jobs } = action.data;

      const creativity = state.all.find(creativity => creativity._id === id);

      if (!creativity) {
        return state;
      }

      return {
        ...state,
        all: [
          ...state.all.filter(creativity => creativity._id !== id),
          {
            ...state.all.find(creativity => creativity._id === id),
            slides: creativity.slides.map(slide => ({
              ...slide,
              elements: slide.elements.map(element => ({
                ...element,
                sources: findSources(jobs, element),
              })),
            })),
          },
        ],
      };
    }
    case 'CREATIVE/SET_DEFAULT_SUCCESS': {
      return {
        ...state,
        all: [
          ...state.all.map(creative => ({
            ...creative,
            selected: action.data.id === creative._id,
          })),
        ],
        isFetching: false,
      };
    }
    case 'CREATIVE/CHANGE_STATUS_SUCCESS': {
      return {
        ...state,
        all: [
          ...state.all.filter(
            creativity => creativity._id !== action.creativeId
          ),
          {
            ...state.all.find(
              creativity => creativity._id === action.creativeId
            ),
            status: action.status,
          },
        ],
        isFetching: false,
      };
    }
    case 'CREATIVITIES/CHANGE_SORT_VALUE':
      return {
        ...state,
        sort: action.value,
      };
    case 'EMERGENCIES/ADD_FAILURE':
    default:
      return state;
  }
};
