import React, { PureComponent, ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import {
  AppState,
  Languages,
  SetLanguageActionCreator,
  loadLanguage,
  login,
  LoginActionCreator,
} from '../../redux/modules';
import {
  Input,
  Button,
  FormBox,
  InstanceName,
  TimeOutModal,
} from '../../components';
import { ConfigConsumer } from '../../providers';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  LoginHeader,
  Recover,
  LoginContainer,
  ButtonContainer,
  ErrorMessage,
  ContainerlText,
  ModalText,
  ModalTile,
  ImageContainer,
  Image,
  ContainerError,
} from './styles';

import { Clock } from '../../assets';

interface IProps {
  loadLanguage: SetLanguageActionCreator;
  login: LoginActionCreator;
  error?: string;
  intl: InjectedIntl;
  invalidToken?: string;
  clock?: string;
}

interface IState {
  username?: string;
  password?: string;
  hideModal?: boolean;
}

interface FormElement {
  name: keyof IState;
  value: string;
}

class LoginPage extends PureComponent<IProps, IState> {
  state = {
    username: '',
    password: '',
    hideModal: false,
  };

  handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.loadLanguage(event.target.value as Languages);
  };

  getLanguages() {
    const langs: { text: string; value: Languages }[] = [
      { text: 'español', value: Languages.ES },
      { text: 'inglés', value: Languages.EN },
      { text: 'francés', value: Languages.FR },
      { text: 'alemán', value: Languages.DE },
    ];

    return langs.map(item => (
      <option key={item.text} value={item.value}>
        {item.text}
      </option>
    ));
  }

  handleChange = (event: ChangeEvent<FormElement>) => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
    });
  };

  handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  };

  handleHideModal = () => {
    this.setState({ hideModal: true });
  };

  render() {
    const { error, invalidToken, intl } = this.props;
    const { hideModal, username, password } = this.state;

    return (
      <ConfigConsumer>
        {config => {
          return (
            <Wrapper>
              <TimeOutModal
                title={'Error'}
                visible={!!invalidToken && !hideModal}
                onOk={this.handleHideModal}
              >
                <ImageContainer>
                  <Image src={Clock} />
                </ImageContainer>
                <ModalTile>
                  <FormattedMessage id="modal.body.title" />
                </ModalTile>
                <ContainerlText>
                  <ModalText>
                    <FormattedMessage id="modal.body.text" />
                  </ModalText>
                </ContainerlText>
              </TimeOutModal>
              <LeftContainer>
                <LoginHeader>
                  <InstanceName name={config.name} logo={config.logo} />
                </LoginHeader>
                <LoginContainer>
                  <FormBox background={config.color}>
                    <form onSubmit={this.handleSubmit}>
                      <Input
                        value={username}
                        name="username"
                        placeholder={'user.username'}
                        onChange={this.handleChange}
                      />
                      <Input
                        type="password"
                        value={password}
                        name="password"
                        placeholder={'user.password'}
                        onChange={this.handleChange}
                      />
                      <ContainerError>
                        <ErrorMessage>
                          {error && <FormattedMessage id={error} />}
                        </ErrorMessage>
                      </ContainerError>
                      <ButtonContainer>
                        <Button
                          htmlType="submit"
                          button={intl!.formatMessage({
                            id: 'route.login',
                          })}
                          onClick={this.handleSubmit}
                        />
                        <Recover
                          to={'/password-reset'}
                          text={intl!.formatMessage({
                            id: 'passwordReset.recover',
                          })}
                        />
                      </ButtonContainer>
                    </form>
                  </FormBox>
                </LoginContainer>
              </LeftContainer>
              <RightContainer background={config.background} />
            </Wrapper>
          );
        }}
      </ConfigConsumer>
    );
  }
}

export default connect(
  (state: AppState) => ({
    language: state.locale.instance.language,
    error: state.user.error,
    invalidToken: state.user.tokenVerifyError,
  }),
  {
    loadLanguage,
    login,
  }
)(injectIntl<any>(LoginPage));
