import React from 'react';
import { connect } from 'react-redux';
import {
  Section,
  ContainerSection,
  ContainerBox,
  ColBox,
  RowBox,
} from './styles';
import { getTemplates } from '../../redux/modules/templates/action';
import BoxEmergencyGroup from './EmergencyGroup';
import { GetTemplatesActionCreator } from '../../redux/modules/templates/types';
import { AppState } from '../../redux/modules';
import { InjectedIntl, injectIntl } from 'react-intl';

interface IProps {
  getTemplates: () => GetTemplatesActionCreator;
  intl?: InjectedIntl;
}

class Emergency extends React.Component<IProps> {
  componentDidMount() {
    this.props.getTemplates();
  }
  render() {
    return (
      <Section>
        <ContainerSection>
          <ContainerBox>
            <RowBox gutter={16}>
              <ColBox span={12}>
                <BoxEmergencyGroup
                  typeHeader={'blue'}
                  typeBody={'secondary'}
                  typeTitle={'white'}
                  titleWrapper={this.props.intl!.formatMessage({
                    id: 'emergency.titleGroups.terrorirsm',
                  })}
                  group="terrorism"
                />
              </ColBox>
              <ColBox span={12}>
                <BoxEmergencyGroup
                  typeHeader={'blue'}
                  typeBody={'secondary'}
                  typeTitle={'white'}
                  titleWrapper={this.props.intl!.formatMessage({
                    id: 'emergency.titleGroups.natural',
                  })}
                  group="natural"
                />
              </ColBox>
            </RowBox>
            <RowBox gutter={16}>
              <ColBox span={12}>
                <BoxEmergencyGroup
                  typeHeader={'blue'}
                  typeBody={'secondary'}
                  typeTitle={'white'}
                  titleWrapper={this.props.intl!.formatMessage({
                    id: 'emergency.titleGroups.technologic',
                  })}
                  group="technologic"
                />
              </ColBox>
              <ColBox span={12}>
                <BoxEmergencyGroup
                  typeHeader={'blue'}
                  typeBody={'secondary'}
                  typeTitle={'white'}
                  titleWrapper={this.props.intl!.formatMessage({
                    id: 'emergency.titleGroups.sanitary',
                  })}
                  group="sanitary"
                />
              </ColBox>
            </RowBox>
          </ContainerBox>
        </ContainerSection>
      </Section>
    );
  }
}

export default connect(
  (state: AppState) => ({
    emergencies: state.templates.all,
  }),
  { getTemplates }
)(injectIntl<any>(Emergency));
