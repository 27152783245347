import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
const activeClassName = 'active';

const DefaultLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 18px;
  pointer-events: ${props => (props.isActive ? 'none' : 'auto')};
  &:hover {
    text-decoration: none;
  }
  &.${activeClassName} {
    text-decoration: none;
  }
`;

export const BasicLink = styled(DefaultLink)`
  color: ${props => props.theme.colors.black};
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
  &.${activeClassName} {
    color: ${props => props.theme.colors.vividBlueLight};
  }
`;

export const HeaderLink = styled(DefaultLink)`
  color: ${props => props.theme.colors.superDarkGrey};
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
  &.${activeClassName} {
    color: ${props => props.theme.colors.blue};
    padding-bottom: 21px;
    border-bottom: 2px solid ${props => props.theme.colors.blue};
  }
`;
