import { Dispatch } from 'redux';
import {
  IInstance,
  InstanceFetchRequestAction,
  InstanceFetchSuccessAction,
  InstanceFetchFailureAction,
  FetchInstanceActionCreator,
  SetLanguageAction,
  Languages,
  SetLanguageActionCreator,
} from './types';
import { request, checkStatus, toJSON } from '../../../utils';

const { REACT_APP_INSTANCE } = process.env;

export const setSpanish = (): SetLanguageAction => ({
  type: 'LOCALE/SPANISH',
});
export const setEnglish = (): SetLanguageAction => ({
  type: 'LOCALE/ENGLISH',
});
export const setDeutsch = (): SetLanguageAction => ({
  type: 'LOCALE/DEUTSCH',
});
export const setFrench = (): SetLanguageAction => ({
  type: 'LOCALE/FRENCH',
});

export const loadLanguage: SetLanguageActionCreator = (lang: Languages) => {
  switch (lang) {
    case Languages.ES:
      return setSpanish();
    case Languages.EN:
      return setEnglish();
    case Languages.DE:
      return setDeutsch();
    case Languages.FR:
      return setFrench();
    default:
      return setFrench();
  }
};

const startInstanceRequest = (): InstanceFetchRequestAction => ({
  type: 'INSTANCE/FETCH_REQUEST',
});

const fetchInstanceSuccess = (data: IInstance): InstanceFetchSuccessAction => ({
  type: 'INSTANCE/FETCH_SUCCESS',
  data,
});

const fetchInstanceFailure = (error: any): InstanceFetchFailureAction => ({
  type: 'INSTANCE/FETCH_FAILURE',
  error,
});

export const fetchInstance: FetchInstanceActionCreator = () => {
  return (dispatch: Dispatch) => {
    dispatch(startInstanceRequest());
    request(`instances-config/instanceId/${REACT_APP_INSTANCE}`)
      .then(checkStatus)
      .then(toJSON)
      .then(json => dispatch(fetchInstanceSuccess(json)))
      .catch(error => dispatch(fetchInstanceFailure(error)));
  };
};
