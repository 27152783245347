import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { AppState } from '../../../redux/modules';
import {
  setFilter,
  Filter,
  StatusFilter,
  statusFilterList,
  FilterAction,
} from '../../../redux/modules/filters';
import { Select } from '../../../components';

import { Body, Container } from './styles';

interface IProps {
  setFilter?: (filter: Filter, value: StatusFilter) => FilterAction;
  currentStatus?: StatusFilter;
  statusList?: any[];
  intl?: InjectedIntl;
  mode?: 'tags' | 'multiple';
}

class FilterBox extends React.PureComponent<IProps> {
  filterHandler = (value: StatusFilter) => {
    const { setFilter } = this.props;
    setFilter && setFilter(Filter.STATUS, value);
  };

  onResetFilterStatus = () => {
    const { setFilter } = this.props;
    setFilter && setFilter(Filter.STATUS, StatusFilter.ALL);
  };

  render() {
    return (
      <Body>
        <FormattedMessage id="creativeList.byStatus" />
        <Container>
          {this.props.statusList && (
            <Select
              items={this.props.statusList}
              defaultValue={this.props.intl!.formatMessage({
                id: 'app.all',
              })}
              onPress={this.filterHandler}
              placeholder={this.props.intl!.formatMessage({
                id: 'app.all',
              })}
              value={this.props.currentStatus}
              mode={this.props.mode}
            />
          )}
        </Container>
      </Body>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    statusList: statusFilterList,
    currentStatus: state.filters.filters.status,
  }),
  {
    setFilter,
  }
)(injectIntl<any>(FilterBox));
