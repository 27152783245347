// @flow
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { getTemplates } from '../../../redux/modules/templates/action';
import {
  addCreativityFromTemplate,
  AddCreativityFromTemplateActionCreator,
} from '../../../redux/modules/creativities';
import { selectTemplateList } from '../../../redux/modules/templates/selector';
import { GetTemplatesActionCreator } from '../../../redux/modules/templates/types';
import { Select } from '../../../components';
import { Body } from './styles';
import { AppState } from '../../../redux/modules';

interface IState {
  template?: {
    _id: string;
    name: string;
  };
}

interface IProps {
  disabled?: boolean;
  getTemplates?: () => GetTemplatesActionCreator;
  addCreativityFromTemplate?: AddCreativityFromTemplateActionCreator;
  templateList: any;
  intl?: InjectedIntl;
}

class TemplateList extends React.Component<IProps, IState> {
  state: IState = {};

  componentDidMount() {
    this.props.getTemplates!();
  }

  onCreateTemplate(_: string, option: any) {
    return this.props.addCreativityFromTemplate!({
      _id: option.props.value,
      name: option.props.children,
    });
  }

  render() {
    const { disabled, templateList } = this.props;

    const favouritesList = templateList.filter(
      (temp: { favourite: boolean }) => temp.favourite
    );
    const regularItems = templateList.filter(
      (temp: { favourite: boolean }) => !temp.favourite
    );

    return (
      <Body>
        <Select
          defaultActiveFirstOption={false}
          favouriteItems={favouritesList}
          items={regularItems}
          disabled={disabled}
          defaultValue={this.props.intl!.formatMessage({
            id: 'select.defaultValue.template',
          })}
          placeholder={this.props.intl!.formatMessage({
            id: 'select.defaultValue.template',
          })}
          value={this.props.intl!.formatMessage({
            id: 'select.defaultValue.template',
          })}
          onPress={this.onCreateTemplate.bind(this)}
          type="model"
        />
      </Body>
    );
  }
}

export default connect(
  (state: AppState) => ({
    disabled: state.creativities.isFetching,
    templateList: selectTemplateList(state),
  }),
  {
    addCreativityFromTemplate,
    getTemplates,
  }
)(injectIntl<any>(TemplateList));
