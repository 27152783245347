import React, { PureComponent, Fragment } from 'react';
import { IFormat, ISlide } from '../../../redux/modules';
import { Icon, CustomSlider } from '../../../components/index';
import Slide from './Slide';

interface IProps {
  activeElement: number;
  format: IFormat;
  slide: ISlide;
}

interface IState {
  zoom: number;
}

export class Canvas extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 0.5,
    };
  }

  fitZoom = value => {
    this.setState({ zoom: 0.5 });
  };

  changeZoom = value => {
    this.setState({ zoom: value / 100 });
  };

  render() {
    return (
      <div>
        <Slide
          activeElement={this.props.activeElement}
          format={this.props.format}
          slide={this.props.slide}
          zoom={this.state.zoom}
        />
        <CustomSlider
          zoom={this.state.zoom}
          onChange={this.changeZoom}
          fitZoom={this.fitZoom}
        />
      </div>
    );
  }
}
