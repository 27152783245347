import styled from 'styled-components';

export const Body = styled.div<any>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px 25px;
  color: #595959;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  padding-left: 8px;
`;
