import styled from 'styled-components';

export const Tag = styled.div`
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  line-height: 22px;
  font-size: ${props => props.theme.sizes.S};
  &.draft-tag {
    border: 1px solid #ffbb96;
    background-color: #fff2e8;
    color: #ffbb96;
  }
  &.waiting-tag {
    border: 1px solid #ffadd2;
    background-color: rgb(255, 236, 244);
    color: #ffadd2;
  }
  &.created-tag {
    border: 1px solid #1890ff;
    background-color: rgb(224, 246, 255);
    color: #1890ff;
  }
  &.completed-tag {
    border: 1px solid #d9d9d9;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.65);
  }
  &.ongoing-tag {
    border: 1px solid #b7eb8f;
    background-color: #f6ffed;
    color: #52c41a;
  }
  &.scheduled-tag {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    color: #1890ff;
  }
`;
export const TextTag = styled.p`
  padding: 0 5px;
  margin: 0;
`;
