import styled from 'styled-components';
import Icon from 'antd/lib/icon';

interface IType {
  typeTagColor?: string;
}

const tagColor = (props: { typeTagColor: any; theme: any }) => {
  const { typeTagColor, theme } = props;
  switch (typeTagColor) {
    case '':
      return `background-color: red`;
    default:
      return `background-color: ${theme.colors.maroon}`;
  }
};

export const Wrapper = styled.div`
  display: inline-flex;
  margin-top: 10px;
  margin-right: 10px;
`;

export const TagTextContainer = styled.div<IType>`
  border-radius: 50px 0 0 50px;
  margin-right: 1px;
  display: flex;
  justify-items: center;
  align-items: center;
  ${tagColor};
`;

export const CloseButtonContainer = styled.div<IType>`
  border-radius: 0 50px 50px 0;
  display: flex;
  justify-items: center;
  align-content: center;
  width: 30px;
  ${tagColor};
`;

export const CloseTagButton = styled.button`
  border-radius: 0 50px 50px 0;
  background-color: #e0205a;
  color: ${props => props.theme.colors.white};
  border: none;
  padding: 5px 5px 5px 5px;
  cursor: pointer;
  display: flex;
  justify-items: center;
  align-content: center;
`;

export const CloseIcon = styled(Icon)`
  padding-top: 2px;
`;
