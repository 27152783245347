export const modal = require('./buttons/svg/modal.svg');
export const modalOnclick = require('./buttons/svg/modal-onclick.svg');
export const date = require('./buttons/svg/date.svg');
export const dateOnclick = require('./buttons/svg/date-onclick.svg');
export const edit = require('./buttons/svg/edit.svg');
export const editOnclick = require('./buttons/svg/edit-onclick.svg');
export const time = require('./buttons/svg/time.svg');
export const timeOnclick = require('./buttons/svg/time-onclick.svg');
export const upload = require('./buttons/svg/upload.svg');
export const uploadOnclick = require('./buttons/svg/upload-onclick.svg');
export const view = require('./buttons/svg/view.svg');
export const viewOnclick = require('./buttons/svg/view-onclick.svg');

export const arrowBlue = require('./icons/arrows/svg/arrow-blue.svg');
export const arrowSelect = require('./icons/arrows/svg/select-arrows.svg');

export const checkOff = require('./icons/checks/svg/check-off.svg');
export const checkOn = require('./icons/checks/svg/check-on.svg');

export const tagDraft = require('./icons/tags/svg/tag-draft.svg');
export const tagRunnig = require('./icons/tags/svg/tag-running.svg');

export const play = require('./icons/video/svg/play.svg');
export const pause = require('./icons/video/svg/pause.svg');

export const clearChanelCityportal = require('./logos/svg/france.svg');

export const cityportal = require('./logos/svg/cityportal.svg');

export const defaultThumbnail = require('./images/default.jpg');

export const Clock = require('./images/clock.svg');

export const zoomOut = require('./icons/zoom/svg/zoom-out.svg');
export const zoomIn = require('./icons/zoom/svg/zoom-in.svg');
export const zoomFit = require('./icons/zoom/svg/zoom-fit.svg');
