import styled from 'styled-components';

export const CustomDivGrey = styled.div`
  background: ${props => props.theme.colors.superlightGrey};
  overflow-y: scroll;
  height: 200px;
  margin-bottom: 10px;
`;

export const CustomDivBodyGrey = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    padding: 5px;
  }
`;
