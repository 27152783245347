import styled from 'styled-components';

export const ContainerLayout = styled.div``;

export const ContainerSection = styled.div`
  margin: 10px 125px 10px 125px;
`;

export const WrapperBodyBox = styled.div`
  display: table;
  margin: 0 auto;
  padding: 19px 0px;
`;

export const Container = styled.div`
  list-style: none;
  display: grid;
  grid-gap: 20px;
  background-color: ${props => props.theme.colors.white};
  box-sizing: content-box;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0;
  padding: 20px 0px;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  justify-items: center;
`;
