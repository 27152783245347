import styled from 'styled-components';
import { layout } from '../../theme';

export const Container = styled.div`
  width: 100%;
  max-width: ${layout.maxWidth}px;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  padding-top: 40px;
`;

export const Preview = styled.div`
  padding-right: 40px;
  padding-left: 40px;
`;
