interface IColors {
  superLightBlue: string;
  lightBlue: string;
  blue: string;
  darkBlue: string;
  vividBlueLight: string;
  megaLightGrey: string;
  superlightGrey: string;
  lightGrey: string;
  grey: string;
  darkGrey: string;
  superDarkGrey: string;
  moreSuperDarkerGrey: string;
  megaDarkGrey: string;
  disabledGrey: string;
  lightRed: string;
  red: string;
  darkRed: string;
  superDarkRed: string;
  deleteRed: string;
  maroon: string;
  white: string;
  alertLightBlue: string;
  alertSoftOrange: string;
  alertPaleYellow: string;
  alertLightGreen: string;
  black: string;
}

interface IFontWeights {
  light: number;
  regular: number;
  medium: number;
  semiBold: number;
  bold: number;
}

interface IFontSizes {
  XXL: string;
  XL: string;
  L: string;
  M: string;
  S: string;
  XS: string;
}

interface ILayout {
  maxWidth: number;
}

export const colors: IColors = {
  superLightBlue: '#C7EFFF',
  lightBlue: '#8AD4F3',
  blue: '#1890FF',
  darkBlue: '#008BC4',
  megaLightGrey: '#FAFAFA',
  vividBlueLight: '#40a9ff',
  superlightGrey: '#F2F2F2',
  lightGrey: '#E8E8E8',
  grey: '#AAAAAA',
  darkGrey: '#BCBCBC',
  superDarkGrey: '#75838F',
  moreSuperDarkerGrey: '#d9d9d9',
  megaDarkGrey: '#000435',
  disabledGrey: '#B7B7B7',
  lightRed: 'rgba(255,58,0,0.3)',
  red: '#FF3900',
  darkRed: '#E83400',
  superDarkRed: '#B42A01',
  deleteRed: '#F5222D',
  maroon: '#E0205A',
  white: '#FFFFFF',
  alertLightBlue: '#99E1FF',
  alertSoftOrange: '#FCCA83',
  alertPaleYellow: '#EDFF9B',
  alertLightGreen: '#97FFDD',
  black: '#000000',
};

export const fontWeights: IFontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const sizes: IFontSizes = {
  XXL: '48px',
  XL: '24px',
  L: '16px',
  M: '14px',
  S: '12px',
  XS: '10px',
};

export const layout: ILayout = {
  maxWidth: 1024,
};
