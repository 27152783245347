import React from 'react';

import { AppState } from '../../redux/modules';
import { connect } from 'react-redux';

import { defaultLogo } from '../../assets/logos';
import { defaultBackground } from '../../assets/images';
import * as logos from '../../assets/logos';
import * as backgrounds from '../../assets/images';

interface IProps {
  locale?: any;
}
interface IState {
  name: string;
  background: any;
  logo: any;
  color: string;
}

const defaultValues: IState = {
  name: 'City Portal',
  background: defaultBackground,
  logo: defaultLogo,
  color: 'rgba(2, 56, 94, 0.7)',
};

const ConfigContext = React.createContext(defaultValues);

class ConfigProvider extends React.Component<IProps, IState> {
  state: IState = {
    name: '',
    background: '',
    logo: '',
    color: '',
  };

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({
      ...nextProps.locale.instance,
    });
  }

  render() {
    const logo = (logos as any)[this.state.logo];
    const background = (backgrounds as any)[this.state.background];
    return (
      <ConfigContext.Provider
        value={{
          ...this.state,
          ...(logo && { logo }),
          ...(background && { background }),
        }}
      >
        {this.props.children}
      </ConfigContext.Provider>
    );
  }
}

export const ConfigConsumer = ConfigContext.Consumer;

export default connect(
  (state: AppState) => {
    return {
      ...state,
    };
  },
  {}
)(ConfigProvider);
