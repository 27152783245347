import React from 'react';

export class NotFound extends React.Component {
  render() {
    return (
      <div>
        <p>Not Found </p>
      </div>
    );
  }
}
