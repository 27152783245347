import React, { PureComponent } from 'react';
import { CheckboxGroupProps } from 'antd/lib/checkbox';

import { CustomCheckboxGroup, CheckBoxGroupContainer } from './styles';

class CheckboxGroup extends PureComponent<CheckboxGroupProps> {
  render() {
    const {
      onChange,
      disabled,
      value,
      className,
      options,
      defaultValue,
    } = this.props;
    return (
      <CheckBoxGroupContainer>
        <CustomCheckboxGroup
          onChange={onChange}
          disabled={disabled}
          value={value}
          className={className}
          options={options}
          defaultValue={defaultValue}
        />
      </CheckBoxGroupContainer>
    );
  }
}

export default CheckboxGroup;
