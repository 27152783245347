import React from 'react';
import {
  date,
  time,
  edit,
  upload,
  view,
  play,
  pause,
  zoomOut,
  zoomIn,
  zoomFit,
} from '../../assets';
import { CustomIcon } from './style';

interface IProps {
  name:
    | 'date'
    | 'time'
    | 'edit'
    | 'upload'
    | 'view'
    | 'play'
    | 'pause'
    | 'zoomOut'
    | 'zoomIn'
    | 'zoomFit';
}

export const getIcon = (name: string) => {
  switch (name) {
    case 'date':
      return date;
    case 'time':
      return time;
    case 'edit':
      return edit;
    case 'upload':
      return upload;
    case 'view':
      return view;
    case 'play':
      return play;
    case 'pause':
      return pause;
    case 'zoomOut':
      return zoomOut;
    case 'zoomIn':
      return zoomIn;
    case 'zoomFit':
      return zoomFit;
    default:
      return time;
  }
};

const Icon = (props: IProps) => {
  return <CustomIcon src={getIcon(props.name)} />;
};

export default Icon;
