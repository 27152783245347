import React, { Component, createRef, Fragment } from 'react';
import { injectIntl, FormattedMessage, InjectedIntl } from 'react-intl';
import {
  PanelsSelections,
  PanelsSelectionsComponent,
  Modal,
  TabsPanel,
  Tab,
} from '../../components';
import { connect } from 'react-redux';
import {
  AppState,
  fetchPlayers,
  selectPlayersAsItem,
  selectGroupsAsItem,
  selectCreativityPlayersAsItem,
  selectCreativityGroupsAsItem,
  updateCreativity,
  updateCreativityProp,
} from '../../redux/modules';

interface IItem {
  id: string;
  title: string;
  subtitle?: string;
}

interface IProps {
  visible: boolean;
  id: string;
  players?: IItem[];
  groups?: IItem[];
  selectedPlayers?: string[];
  selectedGroups?: string[];
  fetchPlayers?: any;
  updateCreativity?: any;
  updateCreativityProp?: any;
  handleDone?: any;
  handleCancel?: any;
  intl?: InjectedIntl;
}

interface IState {
  activeKey: string;
}

class ScreenSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.state = {
      activeKey: this.props.intl!.formatMessage({
        id: 'screenSelection.tabs.option1',
      }),
    };
  }

  private Panels: React.RefObject<PanelsSelectionsComponent> = createRef();

  componentDidMount() {
    this.props.fetchPlayers();
  }

  handleDone = () => {
    this.props.handleDone!();
  };

  handleCancel = () => {
    this.props.handleCancel!();
  };

  onChangeTab(activeKey: string) {
    this.setState({ activeKey }, () => {
      if (this.Panels && this.Panels.current) {
        this.Panels.current.reset();
      }
    });
  }

  onChangeSelectedItems = (
    selectedItems: IItem[],
    type: 'group' | 'player'
  ) => {
    return type === 'group'
      ? this.props.updateCreativityProp(this.props.id, {
          groups: selectedItems.map(item => item.id),
          players: [],
        })
      : this.props.updateCreativityProp(this.props.id, {
          players: selectedItems.map(item => item.id),
          groups: [],
        });
  };

  tabs = [
    {
      type: 'group',
      name: this.props.intl!.formatMessage({
        id: 'screenSelection.tabs.option1',
      }),
    },
    {
      type: 'player',
      name: this.props.intl!.formatMessage({
        id: 'screenSelection.tabs.option2',
      }),
    },
  ];

  getGroupItems = (selected = false) => {
    return this.props.groups!.filter(
      group => this.props.selectedGroups!.includes(group.id)! === selected
    );
  };

  getPlayerItems = (selected = false) => {
    return this.props.players!.filter(
      player => this.props.selectedPlayers!.includes(player.id) === selected
    );
  };

  renderSlideTabs() {
    return this.tabs.map(item => (
      <Tab tab={item.name} key={item.type}>
        {this.renderPanel(item)}
      </Tab>
    ));
  }

  renderPanel(item: any) {
    return (
      <Fragment>
        <FormattedMessage id="screenSelection.phraseSubtitle" />
        {item.type === 'group' ? (
          <PanelsSelections
            initialItems={this.getGroupItems()}
            initialSelectedItems={this.getGroupItems(true)}
            onChange={selectItems =>
              this.onChangeSelectedItems(selectItems, 'group')
            }
          />
        ) : (
          <PanelsSelections
            initialItems={this.getPlayerItems()}
            initialSelectedItems={this.getPlayerItems(true)}
            onChange={selectItems =>
              this.onChangeSelectedItems(selectItems, 'player')
            }
          />
        )}
      </Fragment>
    );
  }

  render() {
    const { visible, intl } = this.props;
    return (
      <Modal
        title={intl!.formatMessage({
          id: 'screenSelection.title',
        })}
        handleCustomOk={this.handleDone}
        handleCustomCancel={this.handleCancel}
        visible={visible}
      >
        <TabsPanel defaultActiveKey="group">{this.renderSlideTabs()}</TabsPanel>
      </Modal>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({
    selectedPlayers:
      (props.id && selectCreativityPlayersAsItem(props.id)(state)) || [],
    selectedGroups: selectCreativityGroupsAsItem(props.id)(state),
    players: selectPlayersAsItem(state),
    groups: selectGroupsAsItem(state),
  }),
  {
    fetchPlayers,
    updateCreativity,
    updateCreativityProp,
  }
)(injectIntl<any>(ScreenSelection));
