import React, { PureComponent } from 'react';
import { Icon } from '../index';
import { ZoomStyle, ZoomFit } from './styles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface IProps {
  zoom: number;
  onChange: any;
  fitZoom: any;
}

export default class CustomSlider extends PureComponent<IProps> {
  constructor(props) {
    super(props);
  }

  zoomChange(event) {
    this.props.onChange(event);
  }

  fitZoom(event) {
    this.props.fitZoom(event);
  }

  sliderStyle() {
    return {
      verticalAlign: 'middle',
      display: 'inline-block',
      margin: '0 20px',
      padding: '4px 0',
      width: '200px',
    };
  }

  render() {
    return (
      <ZoomStyle>
        <div style={{ paddingTop: '20px' }}>
          <Icon name={'zoomOut'} />
          <Slider
            style={this.sliderStyle()}
            trackStyle={{ background: '#1890FF' }}
            handleStyle={{
              border: 'solid 2px #1890FF',
              backgroundColor: '#FFF',
            }}
            min={10}
            max={400}
            defaultValue={50}
            value={this.props.zoom * 100}
            onChange={this.zoomChange.bind(this)}
          />
          <Icon name={'zoomIn'} />
          <ZoomFit onClick={this.fitZoom.bind(this)}>
            <Icon name={'zoomFit'} />
          </ZoomFit>
        </div>
      </ZoomStyle>
    );
  }
}
