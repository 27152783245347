import styled from 'styled-components';
import { Upload } from 'antd';

export const TextUpload = styled.p`
  color: ${props => props.theme.colors.megaDarkGrey};
  font-size: ${props => props.theme.sizes.XS};
  margin: 0 10px;
`;

export const UploadItem = styled(Upload)`
  border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 7px;
  font-size: ${props => props.theme.sizes.S};
  cursor: pointer;
  font-size: ${props => props.theme.sizes.XS};
  .ant-upload {
    font-size: ${props => props.theme.sizes.S};
  }
  &:hover {
    border: 1px solid ${props => props.theme.colors.blue};
    .ant-upload {
      color: ${props => props.theme.colors.blue};
    }
  }
`;

export const CustomDivUpload = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
