import React, { Component } from 'react';
import { DivSelect } from './styles';

interface IProps {
  onClick?: React.MouseEventHandler;
  title: string;
  subtitle?: string;
  active?: boolean;
}

interface IState {
  active?: boolean;
}

class ListItemSelect extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { active: this.props.active };
  }

  render() {
    const { onClick, title, subtitle } = this.props;
    return (
      <DivSelect onClick={onClick} active={this.props.active}>
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle || ''}</span>
      </DivSelect>
    );
  }
}

export default ListItemSelect;
