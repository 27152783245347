import React, { PureComponent } from 'react';
import { CustomSection, CustomTextBanner } from './styles';

interface IProps {
  text: string;
}

export default class Banner extends PureComponent<IProps> {
  render() {
    return (
      <CustomSection>
        <CustomTextBanner>{this.props.text}</CustomTextBanner>
      </CustomSection>
    );
  }
}
