import React from 'react';
import { fetchGeolocation, getTextContent } from '../../../../../../../utils';
import { injectIntl, InjectedIntl } from 'react-intl';

import { Element, ElementProps } from '../Element';

interface ElementExtraProps {
  feature?: any;
  location: string;
}

interface ElementExtraState {
  geo?: any;
  error?: any;
}

interface ExtraProps {
  intl: InjectedIntl;
}

type Props = ElementProps<ElementExtraProps> & ExtraProps;

class LocationElement extends Element<
  ElementExtraProps,
  ElementExtraState,
  ExtraProps
> {
  constructor(props: any) {
    super(props);

    this.state = {
      geo: null,
    };
  }

  fetchTimeout?: NodeJS.Timeout;

  getContent() {
    const { element } = this.props;
    const { feature } = element;
    const { geo, error } = this.state;
    let content = '';

    if (error) {
      content = this.props.intl.formatMessage({ id: 'default.' + feature });
    } else if (geo) {
      content =
        geo[`${feature}`] ||
        this.props.intl.formatMessage({ id: 'default.' + feature });
    }

    return getTextContent({ ...element, content });
  }

  render() {
    return <div style={this.getStyle()}>{this.getContent()}</div>;
  }

  componentDidMount() {
    this.fetchGeo();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props.element;

    if (location !== prevProps.element.location) {
      this.setState({ geo: null, error: null });
      clearTimeout(this.fetchTimeout!);
      this.fetchTimeout = global.setTimeout(this.fetchGeo.bind(this), 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimeout!);
  }

  fetchGeo() {
    const { location } = this.props.element;

    fetchGeolocation(location)
      .then(json => this.setState({ geo: json }))
      .catch(err => this.setState({ error: err }));
  }
}

export default injectIntl(LocationElement);
