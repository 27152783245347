import styled from 'styled-components';

export const ZoomStyle = styled.div`
  height: 150px;
  text-align: center;
  border-top: 1px solid #e4e4e4;
  margin-top: 60px;
  position: relative;
  bottom: 0;
`;

export const ZoomFit = styled.span`
  cursor: pointer;
  height: 30px;
  width: 60px;
  margin-left: 25px;
  position: absolute;
  border-left: 1px solid;
  border-color: #e4e4e4;
`;
