import React from 'react';

import { Container } from './styles';

interface IProps {
  background?: string;
  children: React.ReactElement<any> | string;
}

const defaultBackground = 'rgba(2, 56, 94, 0.7)';

const FormBox = ({ children, background = defaultBackground }: IProps) => {
  return <Container background={background}>{children}</Container>;
};

export default FormBox;
