import styled from 'styled-components';

export const CustomSection = styled.div`
  background: #45b2de;
  min-height: 139px;
  text-align: center;
`;

export const CustomTextBanner = styled.span`
  color: ${props => props.theme.colors.white};
  display: inline-block;
  font-size: 30px;
  margin: 50px 0 0 0;
`;
