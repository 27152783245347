import styled from 'styled-components';

interface IContainerProps {
  fontSize?: string;
  uppercase?: boolean;
  bold?: boolean;
  link?: boolean;
}

export const Container = styled.span<IContainerProps>`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.theme.sizes.L};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'initial')};
  cursor: ${props => (props.link ? 'pointer' : 'auto')};
`;
