import React, { PureComponent, ReactElement } from 'react';
import Icon from 'antd/lib/icon';
import {
  Wrapper,
  TagTextContainer,
  CloseButtonContainer,
  CloseTagButton,
  CloseIcon,
} from './styles';

interface IProps {
  typeTagColor?: string;
  onClick: React.MouseEventHandler;
  children: ReactElement<any>[] | ReactElement<any>;
}

class TagButton extends PureComponent<IProps> {
  public render() {
    const { typeTagColor, children } = this.props;
    return (
      <Wrapper>
        <TagTextContainer typeTagColor={typeTagColor}>
          {children}
        </TagTextContainer>
        <CloseButtonContainer typeTagColor={typeTagColor}>
          <CloseTagButton onClick={this.props.onClick}>
            <CloseIcon type="close" />
          </CloseTagButton>
        </CloseButtonContainer>
      </Wrapper>
    );
  }
}

export default TagButton;
