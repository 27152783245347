import { request, checkStatus, toJSON } from '../../../utils';
import {
  PlayersFetchAllRequestAction,
  IPlayer,
  PlayersFetchAllSuccessAction,
  PlayersFetchAllFailureAction,
  fetchPlayersActionCreator,
} from './types';
import { Dispatch } from 'redux';

const startRequest = (): PlayersFetchAllRequestAction => ({
  type: 'PLAYERS/FETCH_ALL_REQUEST',
});

const requestAllSuccess = (data: IPlayer[]): PlayersFetchAllSuccessAction => ({
  type: 'PLAYERS/FETCH_ALL_SUCCESS',
  data,
});

const requestAllFailure = (error: any): PlayersFetchAllFailureAction => ({
  type: 'PLAYERS/FETCH_ALL_FAILURE',
  error,
});

export const fetchPlayers: fetchPlayersActionCreator = () => {
  return (dispatch: Dispatch) => {
    dispatch(startRequest());
    request('players')
      .then(checkStatus)
      .then(toJSON)
      .then(json => dispatch(requestAllSuccess(json)))
      .catch(error => dispatch(requestAllFailure(error)));
  };
};
