import { Dispatch } from 'redux';
import { request, checkStatus, toJSON } from '../../../utils';
import {
  TemplatesFetchAllRequestAction,
  TemplatesFetchAllSuccessAction,
  TemplatesFetchAllFailureAction,
  GetTemplatesActionCreator,
} from './types';

const startRequest = (): TemplatesFetchAllRequestAction => ({
  type: 'TEMPLATES/FETCH_ALL_REQUEST',
});

const requestAllSuccess = (data: any[]): TemplatesFetchAllSuccessAction => ({
  type: 'TEMPLATES/FETCH_ALL_SUCCESS',
  data,
});

const requestAllFailure = (error: any): TemplatesFetchAllFailureAction => ({
  type: 'TEMPLATES/FETCH_ALL_FAILURE',
  error,
});

export const getTemplates: GetTemplatesActionCreator = () => {
  return (dispatch: Dispatch) => {
    dispatch(startRequest());
    request('templates?published=true')
      .then(checkStatus)
      .then(toJSON)
      .then(json => dispatch(requestAllSuccess(json)))
      .catch(error => dispatch(requestAllFailure(error)));
  };
};
