import { AppState } from '..';
import { Roles } from './types';

export const selectFullName = (state: AppState) =>
  (state.user.user &&
    state.user.user.userMetadata &&
    `${state.user.user.userMetadata.name} ${
      state.user.user.userMetadata.surname
    }`) ||
  '';

export const selectName = (state: AppState) =>
  (state.user.user &&
    state.user.user.userMetadata &&
    state.user.user.userMetadata.name) ||
  '';

export const isAdmin = (state: AppState) =>
  state.user.user &&
  state.user.user.appMetadata &&
  state.user.user.appMetadata.role === Roles.ADMIN;
