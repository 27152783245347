import React from 'react';
import { Languages } from '../../redux/modules/locale/types';
import { AppState, fetchInstance } from '../../redux/modules';
import { connect } from 'react-redux';

interface IProps {
  fetchInstance?: any;
  locale?: any;
}
interface IState {
  language: Languages;
}

const defaultValues: IState = {
  language: Languages.EN,
};

const InstanceContext = React.createContext(defaultValues);

class InstanceProvider extends React.Component<IProps, IState> {
  state: IState = {
    language: Languages.EN,
  };

  componentDidMount() {
    const { fetchInstance } = this.props;
    fetchInstance();

    this.setState({
      ...defaultValues,
    });
  }

  render() {
    return (
      <InstanceContext.Provider
        value={{
          ...this.state,
        }}
      >
        {this.props.children}
      </InstanceContext.Provider>
    );
  }
}

export const InstanceConsumer = InstanceContext.Consumer;

export default connect(
  (state: AppState) => {
    return {
      ...state,
    };
  },
  {
    fetchInstance,
  }
)(InstanceProvider);
