import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { Radio } from 'antd';
import { AppState, Status } from '../../../../redux/modules';
import { FlexHourPickers, Text, RadioButtonsContainer } from '../styles';
import { Picker } from '../../../../components';

const ALL_SELECTED = 1;
const SOME_SELECTED = 2;

interface IProps {
  fromTime: string;
  toTime: string;
  onChange?: any;
  onSelectAll: any;
  creativityId?: string;
  intl?: InjectedIntl;
  status?: Status;
}

interface IState {
  selection: number;
}

const isCompleteDay = (fromTime?: string, toTime?: string) =>
  fromTime === '0:00' && toTime === '23:59';

class HourSelector extends Component<IProps, IState> {
  state: IState = {
    selection: ALL_SELECTED,
  };

  componentDidMount() {
    this.setState({
      selection: isCompleteDay(this.props.fromTime, this.props.toTime)
        ? ALL_SELECTED
        : SOME_SELECTED,
    });
  }

  onChangeFromTime(value: Moment) {
    this.props.onChange('time', 'gte')(value);
  }

  onChangeToTime(value: Moment) {
    this.props.onChange('time', 'lte')(value);
  }

  selectAllHours() {
    this.onChangeFromTime(moment('00:00', 'HH:mm'));
    this.onChangeToTime(moment('23:59', 'HH:mm'));
  }

  onCheckAllChange = () => {
    this.setState({
      selection: ALL_SELECTED,
    });
    this.selectAllHours();
  };

  onCheckSpecificChange = () => {
    this.setState({
      selection: SOME_SELECTED,
    });
  };

  render() {
    const { creativityId, status } = this.props;
    const radioStyle = {
      height: '30px',
      lineHeight: '22px',
      fontSize: '14px',
    };

    return (
      <>
        <RadioButtonsContainer value={this.state.selection}>
          <Radio
            value={ALL_SELECTED}
            style={radioStyle}
            onChange={this.onCheckAllChange}
            disabled={
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
          >
            <FormattedMessage id="HourSelector.allHours" />
          </Radio>
          <Radio
            value={SOME_SELECTED}
            style={radioStyle}
            onChange={this.onCheckSpecificChange}
            disabled={
              status === Status.PUBLISHED ||
              status === Status.PENDING_VALIDATION
            }
          >
            <FormattedMessage id="HourSelector.specificHours" />
          </Radio>
          {this.state.selection === SOME_SELECTED ? (
            <FlexHourPickers>
              <Picker
                {...{
                  id: `${creativityId}_timeFrom`,
                  disabled:
                    status === Status.PUBLISHED ||
                    status === Status.PENDING_VALIDATION
                      ? true
                      : false,
                  value: moment(this.props.fromTime, 'HH:mm'),
                  onPress: this.props.onChange('time', 'gte'),
                  type: 'time',
                }}

                // id={`${creativityId}_timeFrom`}
                // disabled={
                //   status === Status.PUBLISHED ||
                //   status === Status.PENDING_VALIDATION
                // }
                // value={moment(this.props.fromTime, 'HH:mm')}
                // onPress={this.props.onChange('time', 'gte')}
                // type={'time'}
              />
              <Text>
                <FormattedMessage id="editor.visibility.to" />
              </Text>
              <Picker
                {...{
                  id: `${creativityId}_timeTo`,
                  disabled:
                    status === Status.PUBLISHED ||
                    status === Status.PENDING_VALIDATION
                      ? true
                      : false,
                  value: moment(this.props.toTime, 'HH:mm'),
                  onPress: this.props.onChange('time', 'lte'),
                  type: 'time',
                }}
                // id={`${creativityId}_timeTo`}
                // disabled={
                //   status === Status.PUBLISHED ||
                //   status === Status.PENDING_VALIDATION
                // }
                // value={moment(this.props.toTime, 'HH:mm')}
                // onPress={this.props.onChange('time', 'lte')}
                // type={'time'}
              />
            </FlexHourPickers>
          ) : null}
        </RadioButtonsContainer>
      </>
    );
  }
}

export default connect((state: AppState, props: IProps) => ({}))(
  injectIntl<any>(HourSelector)
);
