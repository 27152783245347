import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { fetchCreativities, fetchPlayers } from '../../../redux/modules';
import { auth, getIdToken, verifyToken } from '../../../utils/auth';
import {
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
  LoginActionCreator,
  VerifyFailureAction,
  VerifySuccessAction,
  ILoginResponse,
  IUser,
  LogoutAction,
  PasswordResetActionCreator,
  PasswordChangeRequestAction,
  PasswordChangeSuccessAction,
  PasswordChangeFailureAction,
} from './types';

const loginRequest = (): LoginRequestAction => ({ type: 'USER/LOGIN_REQUEST' });

const loginSuccess = (data: ILoginResponse): LoginSuccessAction => ({
  type: 'USER/LOGIN_SUCCESS',
  data,
});

const loginFailure = (error: any): LoginFailureAction => ({
  type: 'USER/LOGIN_FAILURE',
  error,
});

const passwordChangeRequest = (): PasswordChangeRequestAction => ({
  type: 'USER/PASSWORD_CHANGE_REQUEST',
});

const passwordChangeSuccess = (data: {
  message: string;
}): PasswordChangeSuccessAction => ({
  type: 'USER/PASSWORD_CHANGE_SUCCESS',
  data,
});

const passwordChangeFailure = (error: any): PasswordChangeFailureAction => ({
  type: 'USER/PASSWORD_CHANGE_FAILURE',
  error,
});

const logoutRequest = (): LogoutAction => ({ type: 'USER/LOGOUT' });

export const logout = () => (dispatch: Dispatch) => {
  dispatch(logoutRequest());
  dispatch(push('/login'));
};

export const verifyFailure = (error?: any): VerifyFailureAction => ({
  type: 'USER/VERIFY_FAILURE',
  error,
});

export const verifySuccess = (data: IUser): VerifySuccessAction => ({
  type: 'USER/VERIFY_SUCCESS',
  data,
});

export const login: LoginActionCreator = data => {
  return (dispatch: Dispatch, getState: any, extraArguments: any) => {
    dispatch(loginRequest());
    return auth.client.login(
      { ...data, realm: 'Username-Password-Authentication' },
      async (error: any, loginData) => {
        if (error) {
          return dispatch(
            loginFailure(error.description ? error.description : 'Error login')
          );
        }

        dispatch(
          loginSuccess({
            idToken: loginData.idToken,
            accessToken: loginData.accessToken,
          })
        );

        try {
          await verify()(dispatch, getState, extraArguments);
          dispatch(push('/dashboard'));
        } catch (error) {
          dispatch(loginFailure(error.description));
        }
      }
    );
  };
};

export const verify = () => {
  return async (dispatch: Dispatch, getState: any, extraArguments: any) => {
    const idToken = getIdToken();

    if (!idToken) {
      return dispatch(verifyFailure());
    }

    const { error, user } = verifyToken(idToken);
    if (error || !user) {
      return dispatch(verifyFailure(error));
    }

    try {
      await firstLoad(dispatch, getState, extraArguments);
      dispatch(verifySuccess(user));
    } catch (error) {
      dispatch(verifyFailure(error));
    }
  };
};

const firstLoad = async (
  dispatch: Dispatch,
  getState: any,
  extraArguments: any
) => {
  try {
    await fetchCreativities()(dispatch, getState, extraArguments);
    await fetchPlayers()(dispatch, getState, extraArguments);
  } catch (error) {
    throw error;
  }
};

export const resetPassword: PasswordResetActionCreator = data => {
  return (dispatch: Dispatch) => {
    dispatch(passwordChangeRequest());
    return auth.changePassword(
      {
        email: data.username,
        connection: 'Username-Password-Authentication',
        ...(data.password && { password: data.password }),
      },
      async (error, message) => {
        if (error) {
          return dispatch(passwordChangeFailure(error));
        }

        dispatch(
          passwordChangeSuccess({
            message,
          })
        );
      }
    );
  };
};
