import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextUpload, UploadItem, CustomDivUpload } from './styles';
import { Spin, Icon } from 'antd';
import { UploadProps } from 'antd/lib/upload';

interface IProps extends UploadProps {
  showUploadList?: boolean;
  isPosting?: boolean;
}

export default class Upload extends PureComponent<IProps> {
  render() {
    const { isPosting, ...rest } = this.props;
    const renderUploadIcon = (
      <UploadItem {...rest}>
        <Icon type="upload" />
        {'  '}
        <FormattedMessage id={'component.uploadButton'} />
      </UploadItem>
    );
    const renderLoadingIcon = <Spin />;
    return (
      <CustomDivUpload>
        <TextUpload>
          <FormattedMessage id={'component.upload'} />
        </TextUpload>
        {!isPosting ? renderUploadIcon : renderLoadingIcon}
      </CustomDivUpload>
    );
  }
}
