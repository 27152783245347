import React from 'react';
import { Element, ElementProps } from '../Element';
import { Img } from '../styles';

interface ElementExtraProps {
  url: string;
}

interface ElementExtraState {
  url: string | null;
}

export default class ImageElement extends Element<
  ElementExtraProps,
  ElementExtraState
> {
  constructor(props: ElementProps<ElementExtraProps>) {
    super(props);
    this.state = { url: props.element.url };
  }

  render() {
    const { url } = this.state;
    const style = this.getStyle();

    return <Img src={url || ''} width={style.width} height={style.height} />;
  }

  componentWillReceiveProps(nextProps: ElementProps<ElementExtraProps>) {
    if (nextProps.element.url !== this.props.element.url) {
      this.setState({ url: null });
    }
  }

  componentDidUpdate() {
    if (!this.state.url && this.props.element.url) {
      this.setState({ url: this.props.element.url });
    }
  }
}
