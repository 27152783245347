import React from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import Button from '../../../components/Button';
import { CustomModal, ButtonContainer } from './styles';

interface IProps {
  visible: boolean;
  onOk?: () => void;
  title: string;
  intl: InjectedIntl;
  noFooter?: boolean;
}

interface IState {
  visible: boolean;
}

class TimeOutModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: this.props.visible };
  }

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({ visible: nextProps.visible });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
    if (this.props.onOk) {
      this.props.onOk();
    }
  };

  public render() {
    const { title, intl, noFooter } = this.props;
    const { visible } = this.state;
    return (
      <div>
        <div>
          <CustomModal
            visible={visible}
            width={460}
            closable={false}
            footer={
              !noFooter && [
                <ButtonContainer>
                  <Button
                    key="submit"
                    type="primary"
                    button={intl.formatMessage({
                      id: 'modal.footer.buttonClock',
                    })}
                    onClick={this.handleOk}
                  />
                </ButtonContainer>,
              ]
            }
          >
            {this.props.children}
          </CustomModal>
        </div>
      </div>
    );
  }
}

export default injectIntl(TimeOutModal);
